import {CreateArrayItemMessage2, MoveMessage} from 'Common/Messages';
import {locateSubDocument} from 'Common/ViewUtils';
import {Location} from 'Common/config/PageConfigTypes';
import {PageProvider, usePage} from 'Shared/artists/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {SectionIds, SectionItem, createUrl} from 'Shared/backend/menu/SectionItem';
import { DragDropProvider, DragDropSensors, SortableProvider, DragOverlay, DragEventHandler, createDroppable, DragDropDebugger} from '@thisbeyond/solid-dnd';
import {For, Match, Switch, batch} from 'solid-js';
import {createSignal} from 'solid-js';
import {createStore} from 'solid-js/store';
import {Id} from 'Common/Id';
import {MenuSection, SectionData} from 'Shared/view/backend/MenuSection';
import {menuSelector} from 'Shared/frontend/menuPage/MenuPage';
import {Show} from 'solid-js';
import AjaxConnectionToServer from 'Browser/AjaxConnectionToServer';
import {MenuData} from 'Shared/view/backend/Menu';
import { css } from '@emotion/css';
import { MenuItemsHeader } from './MenuItemsHeader';

const sectionHeaderStyle = () => css({
	display: 'flex',
	padding: '6px 10px',
	alignItems: 'baseline',
	backgroundColor: '#555',
	color: 'white',
	fontSize: 20,
	margin: '5px 0',
	a: {
		paddingRight: 10,
		color: 'white'
	}
});

//TODO maybe economise on calls to css()? Esp wrt SectionItem

export function ListMenuPage(props:SectionData)
{
//	{% set course = menu.courses[params.course] %}
// FIXME breadcrumbs ==> x-breadcrumbs #}
//TODO	<breadcrumbs>{{ crumbs.course(true,breadcrumbs.urls,params.course|capitalize) }}</breadcrumbs> 

	let location = [] as Location;
	for (const s of props.params.sections ?? []) 
		location = [...location,'sections',s];

	/* Add reordering IDs to containers and items */
	props.menu.reorderingId = 's0';
	addIndexes(/*mod*/props.menu,1,1);

	const [menuStore,setMenuStore] = createStore(props.menu);
	const [startDragLocation,setStartDragLocation] = createSignal();

//	const [activeItem, setActiveItem] = createSignal(null);

	const onDragStart:DragEventHandler = e => {
		setStartDragLocation(getLocation(menuStore,e.draggable.id));

//		setActiveItem(`<div>${i.name}</div>`);
	};

	const frontendUrl = `/${menuStore.slug}#`+ menuSelector(menuStore,location);

//	<DragDropDebugger />

	return (
		<PageProvider page={props.page}>
			<BackendWrap>
				<div class='headingBar'>
					<h2>{props.name?.toUpperCase()}
						<a href={frontendUrl} class='linkInline' target='_blank'>
							<LinkOpen/>
						</a>
					</h2>
				</div>

				<h1>{props.menu.title} (single list)</h1>

				<MenuItemsHeader />

				<DragDropProvider 
					onDragStart={onDragStart} 
					onDragOver={({draggable,droppable}) => dragOver(menuStore,setMenuStore,draggable,droppable)} 
					onDragEnd={({draggable,droppable}) => endDrag(props.page,menuStore,setMenuStore,startDragLocation(),draggable,droppable)} 
				>
					<DragDropSensors />

					<Section params={props.params} menu={menuStore} location={[]} sectionIds={[]} />

					<Show when={menuStore.sections}>
						<Sections params={props.params} menu={menuStore} location={['sections']} sectionIds={[]} />
					</Show>

{/*	XXX currently relying on the underlay, which doesnt look too bad
XXX do we really need the 'sortable' div wrapper?
XXX use the activeItem()...?  <div class='row' innerHTML={activeItem()} />

					<DragOverlay>
						{(draggable) => <div class='sortable'>
							<SectionItem item={draggable.data} reorderingId=0 url='' />
						</div>}
					</DragOverlay>
*/}						
				</DragDropProvider>
			</BackendWrap>
		</PageProvider>
	);
}

/* Called when dragged over another item OR into another container. */
function dragOver(menuStore,setMenuStore,draggable,droppable) 
{
	if (!droppable)
		return;

	const sourceSectionId = getContainer(menuStore,draggable.id).reorderingId;
	const targetSectionId = isContainerId(droppable.id) ? droppable.id : getContainer(menuStore,droppable.id).reorderingId;

	if (sourceSectionId != targetSectionId)
		changeSection(menuStore,setMenuStore,targetSectionId,draggable,droppable) 
}

function changeSection(menuStore,setMenuStore,targetSectionId:string,draggable,droppable) 
{
	const targetItemIds = (getContainer(menuStore,targetSectionId).items ?? []).map(i => i.reorderingId);

	let targetIndex = targetItemIds.indexOf(droppable.id);
	if (targetIndex == -1) 
		targetIndex = targetItemIds.length - 1;

	const sourceContainerLocationOfEither = locationOfContainerOrItemsContainer(menuStore,draggable.id);
	const targetContainerLocationOfEither = locationOfContainerOrItemsContainer(menuStore,droppable.id);

	batch(() => {
		setMenuStore(...sourceContainerLocationOfEither,'items',items => items.filter(item => item.reorderingId != draggable.id));

		if (locateSubDocument(menuStore,[...targetContainerLocationOfEither,'items'])==undefined)
			setMenuStore(...targetContainerLocationOfEither,'items',[]);

		setMenuStore(...targetContainerLocationOfEither,'items', items => [...items.slice(0,targetIndex), draggable.data, ...items.slice(targetIndex)]);
	});
}

function endDrag(page,menuStore,setMenuStore,startDragLocation,draggable,droppable)
{	
	const targetSectionId = isContainerId(droppable.id) ? droppable.id : getContainer(menuStore,droppable.id).reorderingId;
	const targetItemIds = (getContainer(menuStore,targetSectionId).items ?? []).map(i => i.reorderingId);

//XXX do we need this 'if' in both functions now?		
	let targetIndex = targetItemIds.indexOf(droppable.id);
	if (targetIndex == -1) 
		targetIndex = targetItemIds.length - 1;

	const sourceContainerLocationOfEither = locationOfContainerOrItemsContainer(menuStore,draggable.id);
	const targetContainerLocationOfEither = locationOfContainerOrItemsContainer(menuStore,droppable.id);

//XXX do we actually want these 'batch'es?		
	batch(() => {
		setMenuStore(...sourceContainerLocationOfEither,'items', items => items.filter(item => item.reorderingId != draggable.id));
		setMenuStore(...targetContainerLocationOfEither,'items', items => [ ...items.slice(0,targetIndex), draggable.data, ...items.slice(targetIndex) ]);
	});
//XXX finally, do we want to extract out any shared code with onDragOver? 		

	/* Update the database: */
//XXX IF possible add ['items',X'] on to the end of both for sanity purposes
	moveInDb(page.server,menuStore._id,startDragLocation,[...targetContainerLocationOfEither,'items',targetIndex]);
}


/* 'draggable' can loop up either an item OR a section. This will look up either. */
function addIndexes(/*mod*/menuOrSection,numItems:number,numContainers:number): {numItems:number,numContainers:number}
{
	for (const item of menuOrSection.items ?? []) {
		const id = 'i' + (numItems++);
		item.reorderingId = id;
	}

	/* Ass sectionIds for sections and subsections: */
	let i = 0;
	for (const section of menuOrSection.sections ?? []) {
//TODO try this again...		
//		if (section.widget != undefined) {
//			const id = 's' + (numContainers++);
			const id = (section.widget==undefined ? 's' : 'w') + (numContainers++);
			section.reorderingId = id;
//		}
		({numItems,numContainers} = addIndexes(section,numItems,numContainers));
	}
	return {numItems:numItems,numContainers:numContainers};
}

function getContainer(menuOrSection,id:string)
{
	if (menuOrSection.reorderingId == id)
		return menuOrSection;

	for (const item of menuOrSection.items ?? []) 
		if (item.reorderingId == id)
			return menuOrSection;

	for (const section of menuOrSection.sections ?? [])  {
		if (section.reorderingId == id)
			return section;

		const subsection = getContainer(section,id);
		if (subsection != null)
			return subsection;
	}
	return null;
};

//TODO delete some of this...
//XXX any sharing possible/desirable with getContainer()
function getLocation(menuOrSection,id:string)
{
	for (let i=0; i < (menuOrSection.items?.length ?? 0); i++) 
		if (menuOrSection.items[i].reorderingId == id)
			return ['items',i];

	for (let i=0; i < (menuOrSection.sections?.length ?? 0); i++) {
		const location = getLocation(menuOrSection.sections[i],id);
		if (location != null)
			return ['sections',i,...location];
	}

	return null;
};

/* 
	If id belongs to a container, return the location of that container.
	If id belongs to an item, return the location of the item's container.
*/
function locationOfContainerOrItemsContainer(menuOrSection,id:string)
{
	if (menuOrSection.reorderingId == id)
		return [];

	for (let i=0; i < (menuOrSection.items?.length ?? 0); i++) 
		if (menuOrSection.items[i].reorderingId == id)
			return [];

	for (let i=0; i < (menuOrSection.sections?.length ?? 0); i++) {
		const location = locationOfContainerOrItemsContainer(menuOrSection.sections[i],id);
		if (location != null)
			return ['sections',i,...location];
	}

	return null;
};

function isContainerId(id:string)
{
	return id[0]=='s' ;
}

interface ISections {
	params,
	menu: MenuData,
	location: Location,
	sectionIds: SectionIds
}

function Sections(props: ISections)
{
	const sections = () => locateSubDocument(props.menu,props.location);

/*
	const noWidgetIds = createMemo(() => {
		const ids = [];
		let j = 0;
		for (const section of sections()) 
			if (section.widget != undefined)
				ids.push(j++);
		return ids;
	});
*/	

//XXX are these sectionIds including widgets???	
	const sectionIds = (section,i:number) => [...props.sectionIds, {index:i, name:(section.title ?? '').replace(/\W/g,'').toLowerCase()}];
//	const sectionIds = (section,i:number) => [...props.sectionIds, {index:noWidgetIds()[i], name:(section.title ?? '').replace(/\W/g,'').toLowerCase()}];

	return (
		<For each={sections()}>{(section,i) => 
			<Show when={section.widget==undefined}>
				<Section
					params={props.params}
					menu={props.menu}
					location={[...props.location,i()]} 
					sectionIds={sectionIds(section,i())}
				/>

				<Show when={section.sections}>
					<Sections 
						params={props.params}
						menu={props.menu}
						location={[...props.location,i(),'sections']} 
						sectionIds={sectionIds(section,i())}
					/>
				</Show>
			</Show>
		}</For>
	);
}

interface ISection {
	params,
	menu: MenuData,
	location: Location,
	sectionIds: SectionIds
}

function Section(props:ISection)
{
	const [show,setShow] = createSignal(true);
	const section = () => locateSubDocument(props.menu,props.location) ?? [];

	const itemIds = () => (section().items ?? []).map(i => i.reorderingId);

	const droppable = createDroppable(section().reorderingId);
	const page = usePage();

	return (
		<div use:droppable 
			classList={{
//				[itemStyle(imagesEnabled)]: true,
//      			'droppable': true,
//      			'draggable-container': true,
//				'!droppable-accept': sortable.isActiveDroppable,
//				[activeUnderlayStyle()]: sortable.isActiveDraggable
			}}
		>
			<SectionsHeader title={section()?.title} show={show} setShow={setShow} menu={props.menu} location={props.location} />

			<Show when={show()}>
				<SortableProvider ids={itemIds()}>
					<For each={section().items ?? []}>{(item,index) => 
						<SectionItem item={item} reorderingId={item.reorderingId}
							url={createUrl(`/admin/menu-item/${props.params.slug}`,props.sectionIds,item.name,index())}
						/> 
					}</For>
				</SortableProvider>

				<button onClick={() => createItemAndRedirect(page,props.params,props.menu,props.location,props.sectionIds)}>Add item</button>
			</Show>
		</div>
	);
}

export function extendedSectionTitle(menu:MenuData,location:Location,title:string)
{
	if (location.length == 1)
		return 'Unclassified';

	let ret = '';
	for (let i=1; i < location.length; i++) 
		if (location[i] == 'sections')
			ret += (ret=='' ? '' : ': ') + locateSubDocument(menu,location.slice(0,i)).title;
	return ret + (ret=='' ? '' : ': ') + title;
}

interface ISectionsHeader {
	menu: MenuData,
	location: Location,
	title: string,
	show: () => boolean,
	setShow: (show:boolean) => void
}

function SectionsHeader(props:ISectionsHeader)
{
	return (
		<div class={sectionHeaderStyle()}>
			<a href='' onClick={() => props.setShow(!props.show())}>
				<Switch>
					<Match when={props.show()}>
						&#8862;
					</Match>
					<Match when={true}>
						&#8863;
					</Match>
				</Switch>
			</a>
			{extendedSectionTitle(props.menu,props.location,props.title)}
		</div>
	);
}

async function createItemAndRedirect(page,params,menu:MenuData,location:Location,sectionIds:SectionIds)
{
	const msg = new CreateArrayItemMessage2(page.name(),'createItem',menu._id,[...location,'items'],
		{status:'draft',imageType:'photo',imageDimensions:'square'}); 

	await page.server.sendOperation(msg);

	const num = locateSubDocument(menu,[...location,'items'])?.length ?? 0;

	window.pageJs( createUrl(`/admin/menu-item/${params.slug}`,sectionIds,'new',num) );
}

function moveInDb(server:AjaxConnectionToServer,docId:Id,source:Location,target:Location)
{
	const msg = new MoveMessage(MenuSection.pageName,'reorderItems',docId,source,target);
	server.sendOperationOptimistically(msg);
}

