import {ClockCircle} from 'Shared/backend/fonts/icons/ClockCircle.svg';
import {ExclamationCircle} from 'Shared/backend/fonts/icons/ExclamationCircle.svg';
import {StatusArchived} from 'Shared/backend/fonts/icons/StatusArchived.svg';
import {StatusDraft} from 'Shared/backend/fonts/icons/StatusDraft.svg';
import {StatusPublished} from 'Shared/backend/fonts/icons/StatusPublished.svg';
import {Match,Switch} from 'solid-js';

export type StatusKey = 'draft' | 'published' | 'unpublished' | 'scheduled';

export interface IStatusIcons {
	status: StatusKey,
	scheduleDate?: XXX
}

export function StatusIcons(props: IStatusIcons)
{
	return (
		<div class='statusIcons'>
			<Switch>
				<Match when={props.status == 'draft'}>
					<StatusDraft />
				</Match>
				<Match when={props.status == 'published'}>
					<StatusPublished />
				</Match>
				<Match when={props.status == 'unpublished'}>
					<StatusArchived />
				</Match>
				<Match when={props.status == 'scheduled'}>

					<Switch>
						<Match when={props.scheduleDate}>
							<ClockCircle />
						</Match>
						<Match when={true}>
							<ExclamationCircle />
						</Match>
					</Switch>

				</Match>
				<Match when={true}>
					<ExclamationCircle />
				</Match>
			</Switch>
		</div>
	);

	//TODO low priority feature add status text to icons on hover 
}
