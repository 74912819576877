import { Match, Show, Switch } from 'solid-js';
import { DateTitle } from 'Shared/frontend/PosterTitle';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/artists/PageProvider';


export function PosterOuter(props)
{
	const characterCount = props.special.tagline?.length ?? 0;
	const charactersLeft = 57 - characterCount;
	const overLimit = charactersLeft < 0;

	return (
		<x-poster class={`specialItem ${props.posterType} ${props.photoDimension}Dimension`}>
			<Show when={props.special.imageType !='suppliedPoster'} >
				<div class='dateBar'>
					<DateTitle times={props.times} />
				</div>
			</Show>

			{props.children}

			<Show when={overLimit || props.special.conditions || props.special.moreInfo}>
				<div class='detailsBackground'>
					<div class='detailsButton'>Details</div>
				</div>
			</Show>
		</x-poster>
	);
}


export function SpecialsPoster(props)
{
	// imageDimensions is for photos only 

	const imageUrl = () => {
		if (!props.special.image?.hash)
			return '';

		const url = (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),
			props.special.image.hash,
			props.special.image,
			'Special-image',
			'medium'
		);
		return url;
	};

	return (
		<Switch>
			<Match when={props.special.imageType == 'suppliedPoster' }>
				<PosterOuter times={props.times} special={props.special} posterType={props.special.imageType} >
					<Switch>
						<Match when={props.special.name != null && props.special.name != ''}>
							<div class='specialName'>{props.special.name}</div>
						</Match>
						<Match when={true}>
							<div class='topSpacer'></div>
						</Match>
					</Switch>

					<div class={`${props.special.imageDimensions}Dimension`}>
						{props.posterDetails}

						<img src={imageUrl()} />
					</div>
				</PosterOuter>
			</Match>
			<Match when={props.special.imageType == 'photoPoster'}>
				<PosterOuter times={props.times} special={props.special} posterType={props.special.imageType} photoDimension={props.special.imageDimensions}>
					<Switch>
						<Match when={props.special.name != null && props.special.name != ''}>
							<div class='specialName'>{props.special.name}</div>
						</Match>
						<Match when={true}>
							<div class='topSpacer'></div>
						</Match>
					</Switch>
					
					<div class={`${props.special.imageDimensions}Dimension`}>
						{props.posterDetails}

						<img src={imageUrl()} />

						<Show when={props.special.tagline}>
							<div class='info' >
								{props.special.tagline}
							</div>
						</Show>
					</div>
				</PosterOuter>
			</Match>
			<Match when={props.special.imageType == 'autoPoster'}>
				<PosterOuter times={props.times} special={props.special} photoDimension='auto'>
					<div class='specialName'>{props.special.name}</div>

					<div class='autoDimension'>
						{props.posterDetails}
						
						<Show when={props.special.tagline}>
							<div class='info'>
								{props.special.tagline}
							</div>
						</Show>
					</div>
				</PosterOuter>
			</Match>
		</Switch>
	);
}

