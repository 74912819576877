
export function ExclamationCircle(props)
{
	return (
		<svg id="exclamation_circle" class="icon" style={`fill:${props.fill ?? 'red'}; stroke:${props.stroke ?? 'red'};`} viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		  <defs></defs>
		  <path d="M 32 2 C 15.459 2 2 15.46 2 32.003 C 2 48.542 15.459 62.003 32 62.003 C 48.539 62.003 62 48.542 62 32.003 C 62 15.46 48.539 2 32 2 Z M 35.958 52.773 L 28.043 52.773 L 28.043 43.87 L 35.958 43.87 L 35.958 52.773 Z M 35.958 40.656 L 28.043 40.656 L 28.043 12.219 L 35.958 12.219 L 35.958 40.656 Z" data-original="#000000">
		  </path>
		</svg>
	);
}
