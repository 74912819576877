import {css} from '@emotion/css';
import {createDraggable, createSortable, transformStyle} from '@thisbeyond/solid-dnd';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/artists/PageProvider';
import {stripZeros} from 'Shared/backend/menu/Price'
import {StatusIcons} from 'Shared/backend/recordStatus/StatusIcons'
import { MenuItem } from 'Shared/model/Menu';
import { Show } from 'solid-js';
import {Match,Switch,For} from 'solid-js'
import { textColour, subHeaderBackground } from '../theme';


const activeUnderlayStyle = () => css({
	opacity: 0.25,

	'.fa-ellipsis-v': {
		cursor: 'grabbing'
	}
});

export const itemStyle = (imagesEnabled:boolean) => css({
	display: 'grid',
	gridTemplateColumns: '1em 2fr 1fr 0.5fr '+(imagesEnabled ? '70px' : '')+' 2em',  
	gridTemplateAreas: '"grabber name prices tags '+ (imagesEnabled ? 'gridImage' : '') + ' status"',
	gridTemplateRows: 'auto', 
	padding: '0.5em',
	borderTop: subHeaderBackground,
	gridGap: '0 1em',
	alignItems: 'center',
	width: '100%',
	minHeight: '4em',

	'.fa-ellipsis-v': {
		color: '#999',
		cursor: 'grab'
	},

	'.thumbnailPoster': {
		maxHeight: 70
	},

	a: {
		color: 'unset'
	}
});


export type SectionIds = {index:number,name:string}[];

export interface ISectionItem {
	item: MenuItem,
	url: string,
	reorderingId: string
}

export function SectionItem(props: ISectionItem)
{
	const sortable = createSortable(props.reorderingId,props.item as any);

	const imageUrl = () => {
		if (!props.item.image?.hash)
			return '';

		const url = (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),
			props.item.image.hash,
			props.item.image,
			'MenuItem-image',
			'tiny'
		);
		return url;
	};

	const imagesEnabled = usePage().data.venue.settings.pages?.menu?.itemType != 'imageless';

	return (
		<div
			ref={sortable.ref}
			classList={{
				[itemStyle(imagesEnabled)]: true,
      			'droppable': true,
      			'draggable-container': true,
				'!droppable-accept': sortable.isActiveDroppable,
				[activeUnderlayStyle()]: sortable.isActiveDraggable
			}}
      		style={transformStyle(sortable.transform)}
		>
			<i class='fas fa-ellipsis-v' {...sortable.dragActivators}></i> 
			<div class='name'>
				<a href={props.url}>
					<Switch>
						<Match when={props.item.brandName && props.item.name}>
							{ props.item.brandName }
							{' '}
							{ props.item.name }
						</Match>
						<Match when={props.item.name}>
							{ props.item.name }
						</Match>
						<Match when={true}>
							<x-warning>
								Add name (or delete if not needed)
							</x-warning>
						</Match>
					</Switch>
				</a>
			</div>

			<div class='prices'>
				<div class='priceWrap'>
					<For each={props.item.prices}>
						{(unit,index) => {
							if (unit.price != undefined) 
								return (
									<div class='priceInner'>
										<span class='unit'>{capitalise(unit.size)} {capitalise(unit.description)} </span> 
										<span class='price'>${stripZeros(unit.price)}</span> 
									</div>
								);
						}}
					</For>
				</div>
			</div>

			<div class='tags'>{(props.item.tags ?? []).join(' ')}</div>

			<Show when={imagesEnabled}>
				<div class='gridImage'>
					<a href={props.url}>
						<img class='thumbnailPoster' src={imageUrl()} />
					</a>
				</div>
			</Show>

			<div class='status'>
				<a href={props.url}>
					<StatusIcons status={props.item.status} scheduleDate={props.item.scheduleDate} />
				</a>
			</div>
		</div>
	);
}

function capitalise(s:string|undefined) 
{
	const s2 = s ?? '';
	return s2.charAt(0).toUpperCase() + s2.slice(1);
}

//XXX also present in MenusPage...
/* The slugs are for human consumption only. The index is used to locate sections */
export function createUrl(prefix:string,sectionIds:SectionIds,name:string,index:number)
{
	let url = prefix;

	for (const i of sectionIds) 
		url += '/' + i.name.replace(/\W/g,'').toLowerCase() +'-'+ i.index;

	url += '/';
	url += (name ?? '').replace(/\W/g,'').toLowerCase();
	url += '-'+index;

	return url;
}

