
import { css } from '@emotion/css';
import {Calendar} from 'Shared/backend/fonts/icons/Calendar.svg';
import {FoodMenu} from 'Shared/backend/fonts/icons/FoodMenu.svg';
import {Hire} from 'Shared/backend/fonts/icons/Hire.svg';
import {Home} from 'Shared/backend/fonts/icons/Home.svg';
import {InfoCircle} from 'Shared/backend/fonts/icons/InfoCircle.svg';
import {PeopleCircle} from 'Shared/backend/fonts/icons/PeopleCircle.svg';
import {StarCircle} from 'Shared/backend/fonts/icons/StarCircle.svg';
import {VosLogo} from 'Shared/backend/fonts/icons/VosLogo.svg';
import {WebsiteCircle} from 'Shared/backend/fonts/icons/WebsiteCircle.svg';
import { borderBackgroundColour, navHeight, navTextColour, desktop, phoneOrTablet, maxBodyWidth } from 'Shared/backend/theme';
import { Match, Switch } from 'solid-js';

const navStyle = () => css({
	maxWidth: maxBodyWidth,
	margin: '0 auto',
    position: 'sticky',
    top: 0,
    height: navHeight,
    zIndex: 5,
    overflow: 'hidden',
    padding: '0 1em',
	background: borderBackgroundColour,
	color: navTextColour,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

	'.vosLogo': {
		width: 50,
		margin: 0
	},

	a: {
		textDecoration: 'none',
		color: 'inherit',

		'&:visited': {
			color: 'inherit'
		}
	},

    'nav-item': {
		display: 'flex',
		alignItems: 'center',

		svg: {
			width: '1.5em',		//FIXME why shrinking?
			margin: '0.5em'
		},

		[desktop]: {
			flexDirection: 'row',
			fontSize: '1em'
		},
		[phoneOrTablet]: {
			flexDirection: 'column',
			fontSize: '.7em',
//			svg: { fontSize: '1em' }
		}
	}
});

export function Nav(props)
{
	return <nav class={navStyle()}>
		<VosLogo fill='#232020' stroke='white'/>

		<Switch>
			<Match when={props.settings.backend.navTemplate=='App/backend/nav/theTownieNav.njk'}>
				<a href='/admin/events'><nav-item><Calendar fill='none' stroke='white'/>Events</nav-item></a>
				<a href='/admin/menus'><nav-item><FoodMenu fill='none' stroke='white'/>Menu</nav-item></a>
				<a href='/admin/specials'><nav-item><StarCircle fill='none' stroke='white'/>Specials</nav-item></a>
				<a href='/admin/hire'><nav-item><Hire fill='none' stroke='white'/>Hire</nav-item></a>
				<a href='/admin/contact'><nav-item><InfoCircle fill='none' stroke='white'/>Info</nav-item></a>
				<a href='/admin/staffResources'><nav-item><PeopleCircle fill='none' stroke='white'/>Staff</nav-item></a>
				<a href='/' target='_blank'><nav-item><WebsiteCircle fill='none' stroke='white' />Website</nav-item></a>
			</Match>
			<Match when={props.settings.backend.navTemplate=='App/backend/nav/theDockNav.njk'}>
				<a href='/admin/events'><nav-item><Calendar fill='none' stroke='white'/>Events</nav-item></a>
				<a href='/admin/menus'><nav-item><FoodMenu fill='none' stroke='white'/>Menu</nav-item></a>
				<a href='/admin/contact'><nav-item><InfoCircle fill='none' stroke='white'/>Info</nav-item></a>
				<a href='/admin/staffResources'><nav-item><PeopleCircle fill='none' stroke='white'/>Staff</nav-item></a>
				<a href='/' target='_blank'><nav-item><WebsiteCircle fill='none' stroke='white' />Website</nav-item></a>
			</Match>
			<Match when={props.settings.backend.navTemplate=='App/backend/nav/differentDrummerNav.njk'}>
				<a href='/admin/home'><nav-item><Home fill='none' stroke='white'/>Home</nav-item></a>
				<a href='/admin/events'><nav-item><Calendar fill='none' stroke='white'/>Events</nav-item></a>
				<a href='/admin/menus'><nav-item><FoodMenu fill='none' stroke='white'/>Menu</nav-item></a>
				<a href='/admin/specials'><nav-item><StarCircle fill='none' stroke='white'/>Specials</nav-item></a>
				<a href='/admin/hire'><nav-item><Hire fill='none' stroke='white'/>Hire</nav-item></a>
				<a href='/admin/contact'><nav-item><InfoCircle fill='none' stroke='white'/>Info</nav-item></a>
				<a href='/admin/staffResources'><nav-item><PeopleCircle fill='none' stroke='white'/>Staff</nav-item></a>
				<a href='/' target='_blank'><nav-item><WebsiteCircle fill='none' stroke='white' />Website</nav-item></a>
			</Match>
			<Match when={props.settings.backend.navTemplate=='App/backend/nav/indieIslandNav.njk'}>
				<a href='/admin/home'><nav-item><Home fill='none' stroke='white'/>Home</nav-item></a>
				<a href='/admin/events'><nav-item><Calendar fill='none' stroke='white'/>Events</nav-item></a>
				<a href='/admin/menus'><nav-item><FoodMenu fill='none' stroke='white'/>Menu</nav-item></a>
				<a href='/admin/specials'><nav-item><StarCircle fill='none' stroke='white'/>Specials</nav-item></a>
				<a href='/admin/hire'><nav-item><Hire fill='none' stroke='white'/>Hire</nav-item></a>
				<a href='/admin/contact'><nav-item><InfoCircle fill='none' stroke='white'/>Info</nav-item></a>
				<a href='/admin/staffResources'><nav-item><PeopleCircle fill='none' stroke='white'/>Staff</nav-item></a>
				<a href='/' target='_blank'><nav-item><WebsiteCircle fill='none' stroke='white' />Website</nav-item></a>
			</Match>
		</Switch>

	</nav>;
}

