//{% import 'App/frontend/macros.njk' as macros %}

import { Show, For, Match, Switch } from 'solid-js';
import { CleanTime, date } from 'Shared/frontend/Macros';

export function weekday(day) 
{
	const dayMap = {
		mon:'Monday', 
		tue:'Tuesday', 
		wed:'Wednesday', 
		thu:'Thursday', 
		fri:'Friday', 
		sat:'Saturday', 
		sun:'Sunday'
	};

	return dayMap[day];
}

export function DaysOfWeeklyActivity(days)
{
	return <For each={days}>{day => weekday(day) + 's'}</For>;
}

export function DateTitle(props)
{
	return (
		<Switch>
			<Match when={props.times.frequency == 'once'}>
				<Show when={props.times.startSingle}>
					{date(props.times.startSingle,'EEE, dd LLL')}
					<CleanTime times={props.times.startSingle} />
				</Show>
			</Match>
			<Match when={props.times.frequency == 'daily'}>
				Daily

				<Show when={props.times.startTime && !props.options.hideTimesForRepeated}>
					<CleanTime times={props.times.startTime} />
				</Show>
			</Match>
			<Match when={props.times.frequency == 'weekly'}>
				{DaysOfWeeklyActivity(props.times.weekdays)}
				{props.days}
				<Show when={props.times.startTime && !props.options.hideTimesForRepeated}>
					<CleanTime times={props.times.startTime} />
				</Show>
			</Match>
			<Match when={props.times.frequency == 'monthly'}>
				{props.times.week}
				{weekday(props.times.weekday)} of month,
				<Show when={props.times.startTime && !props.options.hideTimesForRepeated}>
					<CleanTime times={props.times.startTime} />
				</Show>
			</Match>
		</Switch>
	);
}

