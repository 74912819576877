import {css} from '@emotion/css';
import {SolidImageUploadWidget} from 'Browser/widgets/SolidImageUploadWidget';
import {ArtistUrls} from 'Common/ArtistUrls';
import {usePage} from 'Shared/artists/PageProvider';
import {Image, ImageSize, Url} from 'Shared/model/basic';
import {createSignal,createEffect,createMemo, onMount} from 'solid-js';
import * as imageSize from 'Shared/model/imageSize';
import {SolidImageUploadWidget2} from 'Browser/widgets/SolidImageUploadWidget2';
import {Location} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';

export function uploaderStyle()
{
	return css({
		display: 'flex',
		flexWrap: 'wrap',
		cursor: 'pointer',
		padding: 0,

		'.bf-imagePreview': {
			position: 'relative',
			display: 'inline-block'
		},

		'.bf-loading': {
			color: 'white',
			position: 'absolute',
			top: '50%',
			left:  '50%',
			transform: 'translate(-50%,-50%) scale(0.08)'
		}
	});
}

export function buttonsStyle()
{
//XXX spinner looks dodgy

	return css({
		'.bf-imageUploadButton': {
			position: 'relative',
			marginRight: 5,

			input: {
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				opacity: 0,
				cursor: 'pointer',
			}
		},

		button: {
			cursor: 'pointer',
			padding: '4px 9px'
		},

		'.bf-removeImage:not([disabled])': {
			backgroundColor: '#ff738d',
			borderColor: '#ff5a78'
		},
	});
}

export function Label(props:any) 
{
//FIXME implement this? Or delete.

	return (
		<label>{props.labelText}
			<i class='info-pop fa fa-info-circle' 
				data-content='Use this field to upload a photo or image. Click the button to upload a file 
					from your computer, or if you&rsquo;re using a recent version of Chrome or Firefox, 
					you can simply drag&rsquo;n&rsquo;drop the file from your desktop or from a different browser window.<br>
					Alternatively, you can use a previously uploaded image. To select a previously uploaded file, 
					just type (part) of the file name in the input area, and it will be autocompleted.' 
				data-html='1' data-title='Add image' data-original-title='' title=''
			><span class='sr-only'>Info</span></i>
		</label>
	);
}

export interface IImageUpload extends Image {
	widget: SolidImageUploadWidget,
	fallbackUrl: Url;
} 

/*
	Options include 'ratio', 'minWidth' and 'minHeight' which are all optional. 
	If 'ratio' is supplied only one of either 'minWidth' and 'minHeight' are required to 
	enforce minimum dimensions.
*/
export function ImageUpload(props:IImageUpload)
{
	const config = props.widget.imageUploader.def;

	const [image,setImage] = createSignal(props as IImageUpload|undefined);
	const imageExists = createMemo(() => image()?.hash != undefined);

	const [anchor,setAnchor] = createSignal();
	createEffect(() => props.widget.init(anchor()));

	return (
		<div class={`${uploaderStyle}`} ref={setAnchor} id={props.id}
			on:Loading={e => setImage({dataUrl:e.detail.dataUrl, uploading:true})}
			on:Loaded={e => setImage({...e.detail, uploading:false}) }  
			on:Deleted={() => setImage(undefined) }
		>
			<input type='hidden' class='bf-value' value='' />
			<div class={buttonsStyle()}>
				<button type='button' class='bf-imageUploadButton'>
					<i class='fa fa-upload'></i> {imageExists() ? 'Replace' : 'Upload' } image
					<input type='file' accept='.jpg,.jpeg,.png' autocomplete='off' name='files[]' />
				</button>

				<button type='button' class='bf-removeImage' disabled={!imageExists()}>
					<i class='fa fa-times'></i> Remove image
				</button>

				<x-notes>
					<p>Drop PNG or JPEG file here <i>or</i> click to upload.</p>
					<p>Dimensions at least 1200&times;800px. Max file size is 10MB.</p>
				</x-notes>
			</div>

			<Preview {...image} exists={imageExists()} formats={props.sizes}
					assetFolder={config.assetFolder} size={config.previewSize} fallbackUrl={props.fallbackUrl}
			/>
		</div>
	);
}

export interface IPreview extends Image {
	dataUrl?:string;
	fallbackUrl:Url;
	exists:boolean;
	assetFolder:string;
	size:ImageSize;
	formats: Image;
} 

export function Preview(image:IPreview)
{
	const urls = new ArtistUrls(usePage().build);

	const loadingUrl = urls.resourceUrl('loading.gif');

	const previewUrl = () => {
		const size = image.formats[image.size];
		const useProduction = !image.dev;
		return urls.imageUrl(image.assetFolder,image.hash,image.size,size.format,useProduction);
	};


	// XXX bug - loading not centered properly 
	// XXX suppose we could use <Suspense> for the uploading part
	return (
		<x-pane class='bf-imagePreview'>
			{image.uploading ?
				<>
					<img class='bf-imagePreviewImg' alt='Image preview' src={image.dataUrl} />
					<img class='bf-loading' alt='Loading' src={loadingUrl} />
				</>
			:
				<img class='bf-imagePreviewImg' alt='Preview' 
					src={image.exists ? previewUrl() : urls.resourceUrl(image.fallbackUrl)}  /> 
			}
		</x-pane>
	);
}


//////////////////////////////
//TODO update the artist profile image and use the code below

export interface IImageUpload2 extends Image {
	id: Id,
	location: Location,
	fallbackUrl: Url;
	formats: keyof typeof imageSize;
	previewSize: ImageSize;
	page,
	permission,
	image,
	setImage,
	loadingDataUrl,
	setLoadingDataUrl
} 

/*
	Options include 'ratio', 'minWidth' and 'minHeight' which are all optional. 
	If 'ratio' is supplied only one of either 'minWidth' and 'minHeight' are required to 
	enforce minimum dimensions.
*/
export function ImageUpload2(props: IImageUpload2)
{
	let uploadNode!:HTMLDivElement;
	const imageExists = createMemo(() => {
		return props.image != undefined;
	});

//TODO probably better to incorporate some of this widget here - the event handling below could be avoided I think
//TODO remove 'location' etc here - rely on prps.image instead
	onMount(() => {
		const dims = imageSize[props.formats]?.full;
		if (dims!=undefined)
			//XXX much of this could be integrated more into the current file... much of it is cropping
			(new SolidImageUploadWidget2(props.page.server,props.page.name(),props.permission,props.location,props.id,props.formats,
				true,dims.ratio,dims.minWidth,undefined)).init(uploadNode);
	});

//XXX loadingDataUrl() temporary preview currently not working for menu item previews.
	return (
		<div class={`${uploaderStyle}`} ref={uploadNode} id={props.id}
			on:loading={e => props.setLoadingDataUrl(e.detail.dataUrl)}
			on:loaded={e => { 
				props.setImage(e.detail); 
				props.setLoadingDataUrl(undefined); 
			}}  
			on:deleted={() => { props.setImage(undefined); props.setLoadingDataUrl(undefined);  }}
		>
			<input type='hidden' class='bf-value' value='' />
			<div class={buttonsStyle()}>
				<button type='button' class='bf-imageUploadButton'>
					<i class='fa fa-upload'></i> {imageExists() ? 'Replace' : 'Upload' } image
					<input type='file' accept=".jpg,.jpeg,.png" autocomplete='off' name='files[]' />
				</button>

				<button type='button' class='bf-removeImage' disabled={!imageExists()}>
					<i class='fa fa-times'></i> Remove image
				</button>

				<x-notes>
					<p>Drop PNG or JPEG file here <i>or</i> click to upload.</p>
					<p>Dimensions at least 1200&times;800px. Max file size is 10MB.</p>
				</x-notes>
			</div>

			<Preview2 exists={imageExists()}
				assetFolder={props.permission.assetFolder} 
				size={props.previewSize}
				format={imageSize[props.formats][props.previewSize].format}
				fallbackUrl={props.fallbackUrl}
				image={props.image}
				loadingDataUrl={props.loadingDataUrl}
			/>
		</div>
	);
}

export interface IPreview2 extends Image {
	fallbackUrl:Url;
	exists:boolean;
	assetFolder:string;
	size:ImageSize;
//	format: ImageDetails;
	format: string;
	image,
	loadingDataUrl,
} 

export function Preview2(props:IPreview2)
{
	const urls = new ArtistUrls(usePage().build);   //XXX NOT ArtistUrls

	const loadingUrl = urls.resourceUrl('loading.gif');

	const previewUrl = () => urls.imageUrl(props.assetFolder,props.image.hash,props.size,props.format,!props.dev); 

	// XXX bug - loading not centered properly 
	// XXX suppose we could use <Suspense> for the uploading part
	return (
		<x-pane class='bf-imagePreview'>
			{props.uploading ?
				<>
					<img class='bf-imagePreviewImg' alt='Image preview' src={props.loadingDataUrl} />
					<img class='bf-loading' alt='Loading' src={loadingUrl} />
				</>
			:
				<img class='bf-imagePreviewImg' alt='Preview' 
					src={props.exists ? previewUrl() : urls.resourceUrl(props.fallbackUrl)}  /> 
			}
		</x-pane>
	);
}

