import Cropper from 'cropperjs';
import Assert from 'Common/Assert';
import {VenueUrls} from 'Common/VenueUrls';
import {Location} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import {css} from '@emotion/css';
import {theme} from 'Shared/artists/Theme';
import {forms} from 'Shared/artists/Forms';
import {DeleteImageMessage2} from 'Common/Messages';
import * as imageSize from 'Shared/model/imageSize';
import {IConnectionToServer} from 'Common/IConnectionToServer';


function style()
{
//XXX BUG: spinner appears in wrong spot
	return css(theme,forms,{
		position: 'fixed',
		left: 0,
		right: 0,
		top: 50,
		bottom: 0,
		zIndex: 9999,
		backgroundColor: '#000',

		'#bf-cropperBar': {
			position: 'fixed',
			left: 0,
			right: 0,
			top: 0,
			height: 50,
			backgroundColor: 'white',

			'button': {
				position: 'absolute',
				right: 10,
				top: 10,
				zIndex: 9999
			},

			'#bf-cropperMessage': {
				lineHeight: '50px',  //XXX requires px. Probably a bug in Emotion
				marginLeft: 20
			}
		}
	});
}

/* 
    XXX adding coloured (or just black) borders might be helpful for some images in the future.
    Probably would require a centering button and colour picker too

	ratio, minWidth and minHeight are all optional. If ratio is supplied only one of
	minWidth and minHeight are required to provide minimum dimensions.
 */

export class SolidImageUploadWidget2
{
	private anchorNode!: HTMLElement;

    constructor(
		private server:IConnectionToServer,
		private pageName: string,
		private permission: string,
		private location: Location,
		private docId: Id,
		private formats: keyof typeof imageSize,
		private crop: boolean,
		private ratio: number|undefined,
		private minWidth: number|undefined,
		private minHeight: number|undefined
	)
    {
		this.transformImage = this.transformImage.bind(this);
    }

    init(anchorNode:HTMLElement)
    {
		this.anchorNode = anchorNode;
		const dropPane = anchorNode;

		['dragenter','dragover','dragleave','drop'].forEach(ev => 
			dropPane.addEventListener(ev,e => {
				e.preventDefault();
				e.stopPropagation(); },false));

		['dragenter','dragover'].forEach(ev => 
			dropPane.addEventListener(ev,
				() => dropPane.classList.add('bf-showDragTarget'),false));

		['dragleave','drop'].forEach(ev => 
			dropPane.addEventListener(ev,
				() => dropPane.classList.remove('bf-showDragTarget'),false));

		dropPane.addEventListener('drop',
			/* Ignoring async */ //e => this.handleFiles(anchorNode,Assert.toExists<DataTransfer>(e.dataTransfer).files),false);
			/* Ignoring async */ e => this.handleFiles(Assert.have(e.dataTransfer).files),false);

		Assert.htmlElement(dropPane.querySelector('input[type=file]'))
			//.addEventListener('change',/* Ignoring async */ e => this.handleFiles(anchorNode,Assert.toExists<any>(e.target).files));
			.addEventListener('change',/* Ignoring async */ e => this.handleFiles((<any>e.target).files));  //XXX hacky cast

		this.anchorNode.querySelectorAll('button').forEach(n => {
			if (n.closest('.modal')==null)
				n.addEventListener('click',e => e.stopPropagation())
		});

		this.anchorNode.addEventListener('click', () => 
			Assert.htmlElement(this.anchorNode.querySelector('input[type=file]')).click());

		/* Remove image: */

		Assert.htmlElement(this.anchorNode.querySelector('.bf-removeImage')).addEventListener('click',async () => {
			if (!confirm('Are you sure you want do delete this image?'))
				return;

			const msg = new DeleteImageMessage2(this.pageName,this.permission,this.docId,this.location);
			this.server.sendOperationOptimistically(msg);

//XXX may need to blank out of store
//			this.setImageData(location,undefined);

			this.anchorNode.dispatchEvent(new CustomEvent('deleted'));
		});
	}

	private async handleFiles(files:FileList)
	{
		let minWidth = this.minWidth;
		let minHeight = this.minHeight;

		if (minHeight==null && (this.ratio!=null && minWidth!=null))
			minHeight = Math.round(minWidth / this.ratio);
		if (minWidth==null && (this.ratio!=null && minHeight!=null))
			minWidth = Math.round(minHeight * this.ratio);
		if (minWidth==null) minWidth = 0;
		if (minHeight==null) minHeight = 0;

		let file = files[0];

		let dataUrl:string;
		if (this.crop) {
			const blob = await this.transformImage(file,this.ratio,minWidth,minHeight);
			if (blob==null) return;
//TODO test cancel here wrt null...
			dataUrl = await this.fileOrBlobToDataUrl(blob);
			file = new File([blob],'trimmedImage');
		}
		else {
			dataUrl = await this.fileOrBlobToDataUrl(file);  //XXX NB returning Promise<string|ArrayBuffer|null> 
			if (!await this.checkImageSize(dataUrl,minWidth,minHeight)) {
				alert('The image is too small');
				return;
			}
		}

//		this.imageUploader.uploadingImage(this.location,dataUrl);
		this.anchorNode.dispatchEvent(new CustomEvent('loading',{detail:{dataUrl:dataUrl}}));

		const urls = new VenueUrls(window.build,window.site.key);
		const uploadUrl = urls.uploadImageUrl2(this.pageName,this.permission,this.formats,this.docId,this.location);

		const fieldData = await this.uploadFile(file,uploadUrl);
		this.anchorNode.dispatchEvent(new CustomEvent('loaded',{detail:fieldData}));
	}

	async checkImageSize(image:string,minWidth:number,minHeight:number)
	{
		const im = new Image();
		return await new Promise((resolve,reject) => {
			im.onload = () => {
				resolve(im.height >= minHeight && im.width >= minWidth);
			}
			im.src = image;
		});
	}

	async fileOrBlobToDataUrl(fileOrBlob:File|Blob):Promise<string>
	{
		/*
			For simplicity I'm aways returning a string. Might be better to return an ArrayBuffer instead or
			else pass string|ArrayBuffer back.

			Note on FF for cropping fileOrBlob=blob & FileReader returns a string.
		 */
//XXX when trimming getting a Blob

		return await new Promise((resolve,reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(fileOrBlob);
			reader.onloadend = () => {
				const out = reader.result instanceof ArrayBuffer ? reader.result.toString() : reader.result;
				resolve(Assert.toString(out));
			}
		});
	}

	private async uploadFile(file:File,uploadUrl:string)
	{
		const formData = new FormData();

		formData.append('file',file);

		const ret = await fetch(uploadUrl,{method:'POST',body:formData});
		const body = await ret.json();
		if (body?.errorType!=null)
			throw new Error('Error on server');

//XXX    Could possibly move the image-related functions into this file
		return body;
	}

	private async transformImage(file:Blob,ratio:number|undefined,minWidth:number,minHeight:number):Promise<Blob|null>
	{
		/* Create an image node for Cropper.js: */
		const image = new Image();

		return await new Promise((resolve,reject) => {
			image.onload = async () => 
				resolve(await this.displayCropper(image,file.type,ratio,minWidth,minHeight));
			image.addEventListener('error', err => {
				alert('Invalid image file type');
				reject(new Error('Invalid image file type'));
			});
			image.src = URL.createObjectURL(file);
		});
	}

	private async displayCropper(image:HTMLImageElement,mimeType:string,ratio:number|undefined,minWidth:number,minHeight:number):Promise<Blob|null>
	{
		if (image.height < minHeight || image.width < minWidth) {
			alert('The image is too small');
			return await Promise.resolve(null);
		}

		/* Create the image editor overlay: */
		const editor = document.createElement('div');
		editor.classList.add(style());
//		Assert.htmlElement(document.getElementById('content')).style.height='600px';
		editor.appendChild(image);
		document.body.appendChild(editor);

		const bar = document.createElement('div');
		bar.id = 'bf-cropperBar';
		const confirmButton = document.createElement('button');
		confirmButton.textContent = 'Confirm';
		bar.appendChild(confirmButton);
		const message = document.createElement('div');
		message.id = 'bf-cropperMessage'
		message.innerText = 'Click and drag to crop image';
		bar.appendChild(message);
		editor.appendChild(bar);

		const cropper = new Cropper(image, <any>{
			viewMode: 1,
			autoCropArea: 1.0,
			guides: false,
			center: false,
			zoomable: false,
			imageSmoothingEnabled: true,
			imageSmoothingQuality: 'high',
//BUG the box moves when trying to shrink a minimally sized box
			crop: (event:any) => {
				const width = event.detail.width;
				const height = event.detail.height;

				if (width < minWidth || height < minHeight) {
					cropper.setData({
						width: Math.max(minWidth,width),
						height: Math.max(minHeight,height),
					});
				}
			}
		});
		if (ratio!=undefined)
			cropper.setAspectRatio(ratio);

		const me = this;
		return await new Promise((resolve,reject) => {
			confirmButton.addEventListener('click',async () => 
				resolve(await me.performCrop(editor,cropper,mimeType)));
		});
	}

	async performCrop(editor:HTMLElement,cropper:Cropper,mimeType:string):Promise<Blob|null>
	{
		const canvas = cropper.getCroppedCanvas();
		document.body.removeChild(editor);
//		(<HTMLElement>document.getElementById('content')).style.height='initial';

		return await new Promise((resolve,reject) => 
			canvas.toBlob(blob => resolve(blob),mimeType,0.95)
		);
	}
}

