/* Included for pollyfills. See rollup.app.js - the useBuiltIns options */
import 'core-js';

import {Browser} from 'Browser/Browser';
import pageJs from 'page'; 

window.useVite = false;
const browser = new Browser(pageJs);
browser.run().catch(err => log.error(err));


