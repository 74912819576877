import Logger from 'Common/logs/Logger';
import BrowserTransport from 'Browser/logs/BrowserTransport';
import BrowserFormat from 'Browser/logs/BrowserFormat';
import pageJs from 'page'; 
import * as Run from 'Browser/Run';
import {User} from 'Browser/User';
import {SiteBasics} from 'Common/SiteBasics';
import {PageHandler} from 'Browser/PageHandler';
import { selectPageCreator, selectRoutes } from 'Browser/SiteTypeSelector';
import { SiteType } from 'Common/SiteType';
import {VenueBasics} from 'Common/VenueBasics';
import {Params} from 'Common/pages/IPage';
import {IRoutes} from 'Common/IRoutesProvider';
import {IPageCreator} from 'Common/IPageCreator';
import {hydrateEventHandlers} from 'Browser/Hydrate';
import {LoginOrRegisterPage, LoginPage} from 'Shared/users/LoginPage';
import {SetPassword} from 'Shared/users/SetPassword';


export class Browser
{
	constructor(
		private router:PageJS.Static
	) { }

	async run()
	{
//XXX perhaps raise the level for production   
		window.log = new Logger(undefined);
		log.add(new BrowserTransport(),new BrowserFormat(),'debug');

		window['pageJs'] = pageJs;
		window['nunjucksExtraData'] = {};
		window.run = Run.run as any;
		window.runNoRefresh = Run.runNoRefresh as any;
		window.runOnComponent = Run.runOnComponent;
		window.runOnComponent2 = Run.runOnComponent2;
		window.runOnWidget = Run.runOnWidget;
		window.runOnWidgetNoRefresh = Run.runOnWidgetNoRefresh;
		window.user = window.user ? User.fromJson(window.user) : undefined;

		const pageHandler = new PageHandler();

		const site = createSiteBasics(window.site);

		const pageCreator = selectPageCreator(window.build,site);

		hydrateOrphanPageEvents();

		const routes = selectRoutes(window.build,site);
		addRoutesToPageJs(this.router,pageHandler,routes,pageCreator);
		this.router();
	}
}

export function createSiteBasics(json:any)
{
//XXX could checkout json with Zod	
	if (json.type==SiteType.venue)
		return new VenueBasics(json.id,json.type,json.key,json.document);

	return new SiteBasics(json.id,json.type,json.key);
}

function hydrateOrphanPageEvents()
{
	/*
		Only use templateName for pages that lack configs, e.g. login pages.
		PageHandler will look after the others.
	*/

	let template;
	switch (window.initComponentData.templateName) {
		case 'login':			template = LoginPage; break;
		case 'loginOrRegister':	template = LoginOrRegisterPage; break;
		case 'setPassword':		template = SetPassword; break;
	}

	if (template)
		hydrateEventHandlers({},{config:undefined},template);
}


export function addRoutesToPageJs(router:PageJS.Static,pageHandler:PageHandler,routes:IRoutes,pageCreator:IPageCreator)
{ 
	/* Add all routes and redirects to the PageJs router */
	for (const [path,details] of Object.entries(routes)) {

//FIXME allow redirects again... see notes in ArtistRoutes
//		if (details.type=='page') {

			const [pageClass,paramMapping] = details;
			const pageResolver = (params:Params) => pageCreator.create(pageClass,paramMapping(params));

			router(path,pageHandler.change(pageResolver));
//		}
//		else if (details.type=='redirect')
//			router(path,details.target);
	}


	/* End of every route: */
	router.exit('*', (ctx:PageJS.Context,next) => {
		ctx.state.scrollX = window.scrollX;
		ctx.state.scrollY = window.scrollY;
		ctx.save();

		/* Note that changes to the hash trigger exit(). Don't force a page update for them, but do update the URL. */
		if (ctx.page.current == ctx.path)
			history.pushState(null, 'FeaturePoints Login', ctx.page.prevContext.canonicalPath);
		else
			next();
	});
}


