import {createSignal} from 'solid-js';
import {Match, Show, Switch} from 'solid-js';
import {locateSubDocument} from 'Common/ViewUtils';
import {MenuItem,SectionWraps} from 'Shared/frontend/menuPage/MenuPage';
import { Location } from 'Common/config/PageConfigTypes';
import { MenuItemData } from 'Shared/view/backend/MenuItem';
import { VenueDoc } from 'Shared/model/venue';
import { StatusKey } from 'Shared/backend/recordStatus/StatusIcons';


interface IPreviewTab {
	store: MenuItemData ,
	location: Location,
	venue: VenueDoc,
	status: StatusKey,
	link: string
}

export function PreviewTab(props:IPreviewTab)
{
	const [selected,setSelected] = createSignal('summary');

	return (
		<tab-pane>
			<tab-group id='contentTabs'>
				<x-tab class={selected()=='summary' ? 'active' : ''} onClick={() => setSelected('summary')}>Summary</x-tab>
				<x-tab class={selected()=='full'    ? 'active' : ''} onClick={() => setSelected('full')}   >Full</x-tab>
			</tab-group>

			<tab-pane-group>
				<Switch>
					<Match when={selected() == 'summary'}>
						<tab-pane>
							<Show when={props.status=='published'}>
								<a target='_blank' href={props.link}>
									<button class='small'>View on website menu</button>
								</a>
								<br />
							</Show>
						
							<div id='menuItemSummaryPreview' class={props.venue.settings.frontend.venueClass}>
								<div class='frontend'>
									<PreviewItem {...props} />
								</div>
							</div>
						</tab-pane>
					</Match>
					<Match when={selected() == 'full'}>
						<tab-pane>
							<div id='menuItemFullPreview' class={props.venue.settings.frontend.venueClass}>
								<div class='frontend'>
									<div class='menuItemOverlay'>
										<div class='overlayBody'>
											<PreviewItem {...props} />
										</div>
									</div>
								</div>
							</div>
						</tab-pane>
					</Match>
				</Switch>
			</tab-pane-group>
		</tab-pane>
	);
}

interface IPreviewItem {
	store: MenuItemData ,
	location: Location,
	venue: VenueDoc
}

function PreviewItem(props:IPreviewItem)
{
	const item = () => locateSubDocument(props.store,props.location);

	return (
		<SectionWraps menu={props.store} location={props.location}>
			<MenuItem item={item()} settings={props.venue.settings} menu={props.store} location={props.location} />
		</SectionWraps>
	);
}

