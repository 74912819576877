/*
{% import 'App/backend/breadcrumbs.njk' as crumbs %} 
{#{% import 'App/forms/overlayWidget/overlay.njk' as overlay %} #}

*/

import {DeleteArrayItemMessage2} from 'Common/Messages';
import {locateSubDocument} from 'Common/ViewUtils';
import {EditComponent} from 'Common/components/EditComponent';
import {Location} from 'Common/config/PageConfigTypes';
import {PageProvider} from 'Shared/artists/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {DetailsTab} from 'Shared/backend/menu/DetailsTab';
import {calculateProductType} from 'Shared/backend/menu/MenuDesignerPage';
import {StatusBar} from 'Shared/backend/menu/StatusBar';
import {MenuDoc} from 'Shared/model/Menu';
import {MenuItem, MenuItemData} from 'Shared/view/backend/MenuItem';
import {MenuData} from 'Shared/view/backend/Menu';
import {Show, createMemo} from 'solid-js';
import {createStore} from 'solid-js/store';
import {menuSelector} from 'Shared/frontend/menuPage/MenuPage';

/*
<breadcrumbs>
	{{ crumbs.menuItem(true,breadcrumbs.urls,params.course|capitalize,item.name) }}
</breadcrumbs>
*/			

export function MenuItemPage(props:MenuItemData)
{
	let [store,setStore] = createStore(props.menu);

	const location = createLocation(props.params);

	const editList = props.page.component('editItem') as EditComponent<MenuData,MenuDoc>;

	const item = createMemo(() => locateSubDocument(store,location));

	const productType = calculateProductType(store,location);

	const link = () => `/`+props.menu.slug+`#`+ menuSelector(store,location);

	return (<PageProvider page={props.page}>
		<BackendWrap>
			<div class='topBar'>
				<Show when={props.status=='published'}>
					<a href={link()} class='linkInline' target='_blank'>
						<LinkOpen />
					</a>
				</Show>
			</div>

			<tab-group id='contentTabs'>
				<x-tab class='active'>Details</x-tab>
			</tab-group>

			<tab-pane-group>
				<Show when={true /*selected=='details'*/}>
					<DetailsTab {...props} {...item} component={editList} store={store} setStore={setStore} location={location} productType={productType} link={link()} />
				</Show>
				<Show when={false /*selected=='marketing'*/}>
					{/* TODO include 'App/backend/menu/marketingTab.njk' */}
				</Show>
			</tab-pane-group>

			<StatusBar {...props} component={editList} store={store} setStore={setStore} location={location} link={link()} />

			<a href='' class='delete' onClick={() => deleteItem(props,location)}>Delete item</a>
		</BackendWrap>
	</PageProvider>);
}


async function deleteItem(props,location:Location)
{
	if (!confirm('Delete this menu item?'))
		return;

//XXX if no items left cf deleting out the array entirely	
	const msg = new DeleteArrayItemMessage2(MenuItem.pageName,'editMenu',props.menu._id,location.slice(0,-1),location[location.length-1]);
	await props.page.server.sendOperation(msg)

	const params = props.page.params;

//TODO need to use Urls() or links.ts to help standardise...
	document.location = '/admin/menus/'+params.slug+'/'+params.tail.split('/').slice(0,-1).join('/');
}


function createLocation(params:{sectionIds:{index:number,name:string}[],item:string})
{
	let loc:[string|number][] = [];
	for (const section of params.sectionIds)
		loc = [...loc,'sections',section.index];
	return [...loc,'items',params.item.index];
}

