import {Venue as BackendVenue} from 'Shared/view/backend/Venue';
import {VenueView} from 'Shared/view/VenueView';
import {Home as FrontendHome} from 'Shared/view/frontend/DifferentDrummerHome';
import {Home as BackendHome} from 'Shared/view/backend/DifferentDrummerHome';
import {Menus as BackendMenus} from 'Shared/view/backend/Menus';
import {MenuSection as BackendMenuSection} from 'Shared/view/backend/MenuSection';
import {MenuDesigner as BackendMenuDesigner} from 'Shared/view/backend/MenuDesigner';
import {MenuItem as BackendMenuItem} from 'Shared/view/backend/MenuItem';

import {FoodMenu as FrontendFoodMenu} from 'Shared/view/frontend/DifferentDrummerFoodMenu';
import {DrinksMenu as FrontendDrinksMenu} from 'Shared/view/frontend/DifferentDrummerDrinksMenu';

export class DifferentDrummerView extends VenueView
{
	view()
	{
		return <any>({
			...super.view(),
			[FrontendHome.pageName]:			FrontendHome,

			[BackendVenue.pageName]: 			BackendVenue,
			[BackendHome.pageName]:				BackendHome,

			[BackendMenus.pageName]:			BackendMenus,  //XXX are Solid ones really required here - image uploader probably needs
			[BackendMenuSection.pageName]:		BackendMenuSection,
			[BackendMenuDesigner.pageName]:		BackendMenuDesigner,
			[BackendMenuItem.pageName]:			BackendMenuItem ,

			[FrontendFoodMenu.pageName]:		FrontendFoodMenu,
			[FrontendDrinksMenu.pageName]:		FrontendDrinksMenu,
		});
	}
}

