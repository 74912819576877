import {PageFunctions} from 'Browser/PageFunctions';
import {Components, DocumentQueries, ImageUploaderComponent, Location} from 'Common/config/PageConfigTypes';
import {locateUsingFields} from 'Common/ViewUtils';
import {Home as HomeDoc,home as homeModel} from 'Shared/model/Home';
import { z } from 'zod';
import {ImageUploadWidget} from 'Browser/widgets/ImageUploadWidget';
import * as imageSize from 'Shared/model/imageSize';
import {MultiSelectWidget} from 'Browser/widgets/MultiSelectWidget';
import {Venue} from 'Shared/view/backend/Venue';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {VenueUrls} from 'Common/VenueUrls';
import {HtmlEditorWidget} from 'Browser/widgets/HtmlEditorWidget';


export const homeParams = backendParams.extend({
	tab: z.string()
}).strict();	
export type HomeParams = z.infer<typeof homeParams>;


export const homeData = backendData.extend({
	params: homeParams,
	home: homeModel,
//	events: z.array(eventModel),

	/* Component data types: */
}).strict();
export type HomeData = z.infer<typeof homeData>;


export class Home extends BackendPageConfig<HomeData,HomeParams>
{
	static readonly pageName = 'backend/home';
	name() { return Home.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	widgets(page:IPageWrapper)
	{
		const urls = new VenueUrls(this.build,this.venue.key);

		return {
			[ImageUploadWidget.key()]: new ImageUploadWidget(page),
			[HtmlEditorWidget.key()]: new HtmlEditorWidget(urls),
			[MultiSelectWidget.key()]: new MultiSelectWidget(),
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} home`,
			template: 'App/backend/homePage/standard2.njk'
		};
	}

	documents(params:HomeParams): DocumentQueries 
	{
		return ({
			...super.documents(params),
			home: {
				type: 'object',
				collection: 'home'
			},
		});
	}

	components(): Components<HomeData>
	{
		return ({
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Venue.pageName,component:'breadcrumbs'},
				link: () => '/admin/venue'
			},

			editHome: {
				type:'edit',
				collection: 'home',
				locate: (doc:HomeDoc,) => doc,
			},

			showcase: {
				type: 'repeater',
				selector: '#showcase repeater-items',
				collection: 'home',
				locate:				(doc:HomeDoc,location:Location) => locateUsingFields(doc,['showcase','items'],location),
				locateListParent:	(doc:HomeDoc,location:Location) => locateUsingFields(doc,['showcase','items'],location),
				fieldName:			(location:Location) => location.length==0 ? 'showcase' : 'items',

//TODO Adjust this...
				initialValues: () => ({}),
//TODO Adjust this... (an Edit thing)
				processInput: (location:Location,field:string,value:string) => value,	
			},


			featuredProductImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'home',
				locate: (doc:HomeDoc) => doc,
				field: 'featuredProductImage',
				assetFolder: 'Home-featuredProductImage',
				sizes: imageSize.profile3x2, 
				previewSize: 'medium'
			},
			showcaseImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'home',
				locate: (doc:HomeDoc,location:Location) => locateUsingFields(doc,['showcase','items'],location),
//				locate: (doc:HomeDoc,locationParams:{index:number}) => 
//					(<HomeDoc>doc).showcase!=undefined && (<HomeDoc>doc).showcase![locationParams.index],
				field: 'image',
				assetFolder: 'Showcase-image',
				sizes: imageSize.landscape,  //Ben wrote "FIXME hack" but CJ is happy with this ratio
//				sizes: imageSize.profile2x1,   TODO - use this
				previewSize: 'medium'
			},
			showcaseCarouselImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'home',
				locate: (doc:HomeDoc,location:Location) => locateUsingFields(doc,['showcase','items'],location),
				field: 'image',
				assetFolder: 'Home-showcaseCarouselImage',  //XXX <=== do we need to generalise??
				sizes: imageSize.landscape,
				previewSize: 'large'
			},

		})
	}

	beforeDisplay(data:HomeData) 
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		if (data.home?.featuredProductImage!=undefined)
			funcs.imageUploadUrl(urls,data.home._id,Home.pageName,'featuredProductImage',data.home?.featuredProductImage,'Home-featuredProductImage','home',[],'full','defaults/profile3x2.png');

		let i = 0;
		for (const section of data.home.showcase ?? []) {
			if (section.image != undefined)
				funcs.imageUploadUrl(urls,data.home._id,Home.pageName,'image',section.image,'Showcase-image','home',[i++],'full','defaults/profile3x2.png');
			if (section.layout=='carousel') {
				let j=0;
				for (const image of section.items ?? []) {
					if (image.image != undefined)
						funcs.imageUploadUrl(urls,data.home._id,Home.pageName,'image',image.image,'Home-showcaseCarouselImage','home',[i,j],'full','defaults/profile3x2.png');
					j++;
				}
			}

		}
	}
}



