import {venueDoc} from 'Shared/model/venue';
import { z } from 'zod';
import {PageFunctions} from 'Browser/PageFunctions';
import {Components,DocumentQueries,ImageUploaderComponent, Location} from 'Common/config/PageConfigTypes';
import {locateUsingFields} from 'Common/ViewUtils';
import {ImageUploadWidget} from 'Browser/widgets/ImageUploadWidget';
import * as imageSize from 'Shared/model/imageSize';
import {HtmlEditorWidget} from 'Browser/widgets/HtmlEditorWidget';
import {hire as hireModel,Hire as HireDoc} from 'Shared/model/Hire';
import {MultiSelectWidget} from 'Browser/widgets/MultiSelectWidget';
import {Venue} from 'Shared/view/backend/Venue';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import Assert from 'Common/Assert';



export const hireParams = backendParams.extend({ 
	tab: z.string().optional()
}).strict();	
export type HireParams = z.infer<typeof hireParams>;

export const hireData = backendData.extend({
	params: hireParams,
	venue: venueDoc,
	hire: hireModel,

	/* Component data: */
//TODO tie the type to EditableList2.localData somehow
	spaces: z.object({current:z.number().optional()}),
	tabs: z.any()				//XXX any
}).strict();
export type HireData = z.infer<typeof hireData>;


export class Hire extends BackendPageConfig<HireData,HireParams>
{
	static readonly pageName = 'backend/hire';
	name() { return Hire.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	widgets(pageWrapper:IPageWrapper)
	{
		const urls = new VenueUrls(this.build,this.venue.key);

		return {
			[ImageUploadWidget.key()]: new ImageUploadWidget(pageWrapper),
			[HtmlEditorWidget.key()]: new HtmlEditorWidget(urls),
			[MultiSelectWidget.key()]: new MultiSelectWidget()
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} hire`,
			template: 'App/backend/hire/hirePage.njk'
		};
	}

	documents(params:HireParams): DocumentQueries 
	{
		return ({
			... super.documents(params),
			venue: {
				type: 'object',
				collection: 'venue'
			},
			hire: {
				type:'object',
				collection: 'hire'
			}
		});
	}

	components():Components<HireData>
	{
		return ({
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Venue.pageName,component:'breadcrumbs'},
				link: () => 'backend/contact'
			},
			tabs : {
				type: 'tabs',
				names: ['spaces','documents','introduction','spacesOld'],
				initial: (data:HireData) => data.params.tab ?? 'spaces'
			},
/*TODO
        "overlay_Catering": { 
            "type": "extraData",
            "initial": false
        },
        
        "overlay_Drinks": {
            "type": "extraData",
            "initial": false
        },
*/		
			editHire: {
				type:'edit',
				collection: 'hire',
				locate: (doc:HireDoc,) => doc,
			},

			spaces: {
				type: 'repeater',
				selector: '#spaces repeater-items',
				collection: 'hire',
				locate: (doc:HireDoc,location:Location) => doc.space[Assert.toNumber(location[0])],	
				locateListParent: (doc:HireDoc) => doc,
				fieldName: ()=>'space',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value
			},

			showcase: {
				type: 'repeater',
				selector: '#showcase repeater-items',
				collection: 'hire',
				locate:				(doc:HireDoc,location:Location) => locateUsingFields(doc,['showcase','items'],location),
				locateListParent:	(doc:HireDoc,location:Location) => locateUsingFields(doc,['showcase','items'],location),
				fieldName:			(location:Location) => location.length==0 ? 'showcase' : 'items',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},

			showcaseImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'hire',
				locate: (doc:HireDoc,location:number[]) => 
					(<HireDoc>doc).showcase!=undefined && (<HireDoc>doc).showcase![location[0]],
				field: 'image',
				assetFolder: 'Showcase-image',
				sizes: imageSize.landscape,  //FIXME hack
//				sizes: imageSize.profile2x1,   TODO - use this
				previewSize: 'medium'
			},
			showcaseCarouselImage: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'hire',
				locate: (doc:HireDoc,location:Location) => locateUsingFields(doc,['showcase','items'],location),
				field: 'image',
				assetFolder: 'Hire-showcaseCarouselImage',  //XXX <=== do we need to generalise??
				sizes: imageSize.landscape,
				previewSize: 'large'
			},

			documents: {
				type: 'repeater',
				selector: '#documents repeater-items',
				collection: 'hire',
				locate: (doc:HireDoc,location:Location) => doc.document[Assert.toNumber(location[0])],	
				locateListParent: (doc:HireDoc) => doc,
				fieldName: ()=>'document',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value
			},


//			...repeater('gallery','galleryItem','hire'),
//			...repeater('document','documentItem','hire'),
//			...repeater('email','emailItem','venue'),

			photo: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'hire',
				locate: (doc:HireDoc,location:Location) => doc.space && doc.space[Assert.toNumber(location[0])],
				field: 'photo',
				assetFolder: 'Space-photo',
				sizes: imageSize.landscape,
				previewSize: 'medium'
			},
			photo2: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'hire',
				locate: (doc:HireDoc,location:Location) => doc.space && doc.space[Assert.toNumber(location[0])],
				field: 'photo2',
				assetFolder: 'Space-photo2',
				sizes: imageSize.landscape,
				previewSize: 'medium'
			},
			photo3: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'hire',
				locate: (doc:HireDoc,location:Location) => doc.space && doc.space[Assert.toNumber(location[0])],
				field: 'photo3',
				assetFolder: 'Space-photo3',
				sizes: imageSize.landscape,
				previewSize: 'medium'
			},
			photo4: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'hire',
				locate: (doc:HireDoc,location:Location) => doc.space && doc.space[Assert.toNumber(location[0])],
				field: 'photo4',
				assetFolder: 'Space-photo4',
				sizes: imageSize.landscape,
				previewSize: 'medium'
			},
		});
	}

	beforeDisplay(data:HireData) 
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		let k=0;
		for (const space of data.hire.space ?? []) {
			if (space.photo==undefined)
				space.photo = <any>{};
			funcs.imageUploadUrl(urls,data.hire._id,Hire.pageName,'photo',space.photo!,'Space-photo','hire',[k],'medium','defaults/profile3x2.png');

			if (space.photo2==undefined) 
				space.photo2 = <any>{};
			funcs.imageUploadUrl(urls,data.hire._id,Hire.pageName,'photo2',space.photo2!,'Space-photo2','hire',[k],'medium','defaults/profile3x2.png');

			if (space.photo3==undefined) 
				space.photo3 = <any>{};
			funcs.imageUploadUrl(urls,data.hire._id,Hire.pageName,'photo3',space.photo3!,'Space-photo3','hire',[k],'medium','defaults/profile3x2.png');

			if (space.photo4==undefined) 
				space.photo4 = <any>{};
			funcs.imageUploadUrl(urls,data.hire._id,Hire.pageName,'photo4',space.photo4!,'Space-photo4','hire',[k],'medium','defaults/profile3x2.png');

			k++;
		}

		let i = 0;
		for (const section of data.hire.showcase ?? []) {
			if (section.image != undefined)
				funcs.imageUploadUrl(urls,data.hire._id,Hire.pageName,'image',section.image,'Showcase-image','hire',[i++],'full','defaults/profile3x2.png');

			console.log('DifferentDrummerHire beforeDisplay()  section.layout:',section.layout);

			let j=0;
			for (const image of section.items ?? []) {
				if (image.image != undefined)
					funcs.imageUploadUrl(urls,data.hire._id,Hire.pageName,'image',image.image,'Hire-showcaseCarouselImage','hire',[i,j],'full','defaults/profile3x2.png');
				j++;
			}
		}


		funcs.updateLocationWithTabs(this.inBrowser,`/admin/hire/${data.tabs.selected}`);


		
	}
}

