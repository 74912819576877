import UserError from 'Browser/UserError';
import Assert from 'Common/Assert';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {IPageData} from 'Common/PageConfig';
import {IPage} from 'Common/pages/IPage';
import {NunjucksPageWrapper} from 'Browser/pages/NunjucksPageWrapper';
import {SolidPageWrapper} from 'Browser/pages/SolidPageWrapper';
import {Params} from 'Common/pages/IPage';
import {hydrateEventHandlers,pageReferences} from 'Browser/Hydrate';


export class PageHandler
{
	private currentPageWrapper:IPageWrapper|undefined;
	private lastPageName = '';
	/* Get data using SSR unless Vite */
	private firstLoad = !window.useVite;

	constructor()
	{
		this.change = this.change.bind(this);
	}

	change(pageResolver: (params:Params) => IPage<IPageData>):PageJS.Callback  
	{
		return async (context:PageJS.Context,next:()=>any) => this.changePage(pageResolver,context);
	}

	private async changePage(pageResolver: (params:Params) => IPage<IPageData>, context:PageJS.Context)
	{
		try {
			/*
				Could validate the params here, but they will be checked before page render anyway,
				and passing the validator would add extra complexity to the routes.
			 */

			const page = pageResolver(context.params);
			if (page==null)
				throw new UserError(`Unknown page2`);	//TODO

			/* For Vite: load in the page CSS and JS dependencies */
			if (window.useVite) {
				const includes = page.config.includes();

				//TODO possibly time these operations. The caching might already be fine... 
				//     The initial script loading could be done in parallel.
				for (const i of includes.css)
					document.head.insertAdjacentHTML('beforeend',`<link rel=stylesheet href="${i.url}" />`);

				for (const i of includes.js)
					await loadScript(i.url);
			}

			if (this.firstLoad) 
				this.handleFirstLoad(page);
			else 
				await this.handleLoad(page);

			this.lastPageName = page.name();

			/* 
				Scroll to the point where we left off, otherwise go to the page top.
				Only parent pages have their positions stored in the history, so 
				revisiting old branches will take us back to the tops of pages.
				If we are unhappy with this cf storing page positions in BrowserSite instead.
			 */
			window.scrollTo({top:context?.state?.scrollY ?? 0});

			/* If I want to remember all pages... but likely to get confusing after a while... */
			//const pos = this.site.scrollPosition[context.pathname]?.y;
			//window.scrollTo({top:pos ?? 0});
		}
		catch(err) {
			if (err instanceof UserError)
				alert(err.toString());
			else {
				log.error(err);
				alert('Operation failed');
			}
		}

		const root = Assert.htmlElement(document.getElementById('root'));
		root.classList.remove('loading');
	}

	private handleFirstLoad(page:IPage<IPageData>)
	{
		this.firstLoad = false;

		page.data = deepMerge(
			/* The settings include template functions that aren't serialised */
			page.config.settings(),
			window.initComponentData
		);

		const isNunjucks = typeof page.data.template == 'string';
		const pageWrapper = this.handlePageWrapper(page,isNunjucks);

		if (!isNunjucks) 
			hydrateEventHandlers(pageWrapper,page,page.config.settings().template);

		pageWrapper.postFirstDisplayInit();

		document.body.classList.remove('preload');
		document.body.classList.add('loaded');

		/* Perform the initial load and allow the browser to reclaim this memory */
		window.initComponentData = null;
	}

	private handlePageWrapper(page:IPage<IPageData>,isNunjucks:boolean)
	{
		const pageWrapper = isNunjucks ? new NunjucksPageWrapper(page) : new SolidPageWrapper(page);

		//XXX perhaps should happen after page.load()... we may wish to remain on current page in the case of an error
		if (this.currentPageWrapper!=undefined) 
			this.currentPageWrapper.leave();
		this.currentPageWrapper = pageWrapper;

		window.pageWrapper = pageWrapper;

		return pageWrapper;
	}

	private async handleLoad(page:IPage<IPageData>)
	{
		const root = Assert.htmlElement(document.getElementById('root'));
		root.classList.add('loading');

		await page.load();

		const isNunjucks = typeof page.data.template == 'string';
		const pageWrapper = this.handlePageWrapper(page,isNunjucks);

		if (!isNunjucks) 
			page.data = {
				...page.data,
				...pageReferences(pageWrapper,page)
			};

		if (this.lastPageName == page.name())
			await pageWrapper.refresh();
		else
			await pageWrapper.display();

		if (window.useVite) {
			pageWrapper.postFirstDisplayInit();

			document.body.classList.remove('preload');
			document.body.classList.add('loaded');

			/* Perform the initial load and allow the browser to reclaim this memory */
			window.initComponentData = null;
		}
	}
}

//	XXX maybe move these to common/SettingsUtils.ts or similar. Possibly use in VenuePageConfig.ts

/*
	Successfully excludes arrays, functions, null, undefined, Maps, Sets, etc 
	(shallow check only).
*/
function isPlainObject(value:any) 
{
	return value instanceof Object && Object.getPrototypeOf(value) == Object.prototype;
}

/*
	Merge objects by object keys only (leave arrays alone).
	obj2 properties takes preference.
	This is an immutable function but note that it is NOT a full clone:
	branches that are not shared are incorporated using references.
	Cycles not handled.
*/
function deepMerge(obj1:any,obj2:any)
{
	if (!isPlainObject(obj1) || !isPlainObject(obj2))
		/* Object 2 takes preference in case of a difference */
		return obj2;

	const ret:any = {};

	for (const key of [...Object.keys(obj1), ...Object.keys(obj2)]) 
		if (key in obj1 && key in obj2)
			ret[key] = deepMerge(obj1[key],obj2[key])
		else 
			ret[key] = key in obj2 ? obj2[key] : obj1[key];

	return ret;
}

async function loadScript(url:string) 
{
	return await (new Promise((resolve,reject) => {
		const s = document.createElement('script');
		s.src = url;
		s.onload = resolve;
		s.onerror = reject;
		document.head.appendChild(s);
	}));
}
