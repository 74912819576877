// TODO create a product search to insert products like we do with artist search

import {ImageSelector} from 'Shared/backend/menu/ImageSelector';
import {productTypes, singleLineStyle} from 'Shared/backend/menu/MenuDesignerPage';
import {PreviewTab} from 'Shared/backend/menu/PreviewTab';
import {BeerDescription} from 'Shared/backend/menu/BeerDescription';
import {WineDescription} from 'Shared/backend/menu/WineDescription';
import {MultiSelect, Price, SingleSelect, Wrap, Text} from 'Shared/forms/Inputs';
import {Switch,Match, createSignal} from 'solid-js';
import {StoreRepeater, addItemAndOpen} from 'Shared/forms/StoreRepeater';
import {Show} from 'solid-js';
import { MenuItemData } from 'Shared/view/backend/MenuItem';
import { Location } from 'Common/config/PageConfigTypes';

//XXX cf injecting product type mini-templates

interface IDetailsTab {
	store;		//XXX ==> menu?
	location: Location;
	image;
	productType: keyof typeof productTypes
}

export function DetailsTab(props:IDetailsTab)
{
	let itemsNode!:HTMLElement;

	const image = () => props.image; 
	const setImage = image => props.setStore(...props.location,'image',image);

	const [loadingDataUrl,setLoadingDataUrl] = createSignal();

	const tags = {
		GF: 'Gluten Free', 
		VG: 'Vegan', 
		V: 'Vegetarian', 
		DF: 'Dairy Free',
		'0%': 'Zero Alcohol',
		'☺': '☺'
	};

	return (
		<tab-pane>
			<div class='fieldsAndPreviewWrap'>
				<div class='fieldsWrap'>
					<Switch>
						<Match when={props.productType == 'mixed'}>
							<Wrap label='Product type' required={true}>
								<SingleSelect {...props} field='productType' options={productTypes} required={true} />
							</Wrap>
						</Match>
						<Match when={true}>
							<Wrap classes={singleLineStyle()} label='Product type' disable={true}>
								<div>{productTypes[props.productType]}</div>
							</Wrap>
						</Match>
					</Switch>

					<header-regular>Description</header-regular>			           

					<Switch>
						<Match when={props.productType=='beers' || props.productType=='wines'}>
							<div class='brandAndName'> 
								<Wrap label='Brand Name' required={false}>
									<Text {...props} field='branchName' />
								</Wrap>
								<Wrap label='Production Name' required={true}>
									<Text {...props} field='name' />
								</Wrap>
							</div>
						</Match>
						<Match when={true}>
							<Wrap label='Product Name' required={false}>
								<Text {...props} field='name' />
							</Wrap>
						</Match>
					</Switch>

					<Wrap label='Tags' required={false}>
						<MultiSelect {...props} field='tags' options={tags} />
					</Wrap>

					<Switch>
						<Match when={props.productType=='beers'}>
							<BeerDescription {...props} />
						</Match>
						<Match when={props.productType=='wines'}>
							<WineDescription {...props} />
						</Match>
						<Match when={true}>
							<Wrap label='Short description' required={false}>
								<Text {...props} field='tagline' />
							</Wrap>
						</Match>
					</Switch>

					<header-regular>Prices and add ons</header-regular>

					<x-field>
{/* what is this?					
						{% set selection = [prices.current[2]] if prices.current.length >= 3 else [] %}
*/}						
						<repeater-pane>
							<StoreRepeater
								{...props}
								ref={itemsNode}
								docId={props.store._id}
								permission='editMenu'
								field='prices'
								renderTitle={i => <>{i.size} {i.description} {i.price}</> }
							>
								{(item,index) => <UnitPrice {...props} {...item} location={[...props.location,'prices',index]} />}
							</StoreRepeater>

			                <button onClick={() => addItemAndOpen({...props,page:props.page,permission:'editMenu',
														location:props.location,field:'prices'}, itemsNode,{})}>
								<i class='fas fa-plus'/> Add unit price
							</button>
						</repeater-pane>
					</x-field>

					<Show when={props.venue.settings.pages.menu.itemType!='imageless'}>
						<ImageSelector {...props} 
							image={image()} setImage={setImage}
							loadingDataUrl={loadingDataUrl()} setLoadingDataUrl={setLoadingDataUrl}
						/>
					</Show>
				</div>

				<div class='previewWrap'>
					<PreviewTab {...props} />
				</div>
			</div>
		</tab-pane>
	);
}

function UnitPrice(props)
{

//TODO test wines. Shouldnt be an add on should it?
//			{unitOptions: {glass:'Glass',bottle:'Bottle'} } :
	const beerUnits = {can:'Can',bottle:'Bottle',stubby:'Stubby',midi:'Midi',schooner:'Schooner',pint:'Pint',jug:'Jug'};

	return (
		<Switch>
			<Match when={props.productType == 'beers'}>
				<Wrap label='Size' required={true}>
					<SingleSelect {...props} field='size' options={beerUnits} />
				</Wrap>
				 
				<Wrap label='Price' required={true}>
					<Price {...props} field='price' />
				</Wrap>
			</Match>
			<Match when={props.productType == 'wines'}>
				TODO wines 
			</Match>
			<Match when={true}>
				<Wrap label='Price' required={true}>
					<Price {...props} field='price' />
				</Wrap>

				<Wrap label='Description (optional, but useful for add ons)' required={false}>
					<Text {...props} field='description' />
				</Wrap>
			</Match>
		</Switch>
	);
}

