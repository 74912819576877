import { z } from 'zod';
import * as t from 'Shared/model/basic';

export const price = z.object({
	price: z.string().optional(),
	description: z.string().optional()
});

//TODO split off wines etc...
export const menuItem = z.object({
	name: z.string(),	
	tagline: z.string(),   //XXX cf max 1000 chars
	moreInfo: z.string(),  //XXX cf max 20000 chars

	variety: z.string(),
	varietyOther: z.string(),
	year: z.number(), 		//XXX --> year()
	tags: z.array(z.string()), 
	region: z.string(),

//TODO replace with brandId??
//Brand related stuff:
	brandName: z.string(),		//XXX maybe just for beers, wines, etc
	state: z.string(),
	country: z.string(),

	//CJ not using extraFields yet, ben might have good ideas for it
//	['extraFields', { type: ['string', 100] }],

		//    ['addOns', { type: ['string', 200] }],
//		['priceOne', { type: ['price'] }],
//		['priceTwo', { type: ['price'] }],
//		['priceThree', { type: ['price'] }],
//		['priceFour', { type: ['price'] }],

//FIXME add prices in here...

	//CJ did get replace working so did a delete and appendTo to increase description string from 200-300

	description: z.string(),  //XXX max 2000

	imageSource: z.string(),	
	imageType: z.string(),   //TODO
	imageDimensions: z.string(),  //TODO

	image: t.image,
//FIXME replace square etc	

	prices: z.array(price),

//XXX suspect we dont REALLY want this here. Makes more sense at top menu level
	status: z.string(),		//TODO put options. NB used in events, specials too
	scheduleDate: t.dateTime
})
.partial().required({
	status: true
});

export type MenuItem = z.infer<typeof menuItem>;

//export const menuItems = z.array(menuItem);

export const courses = z.record(z.array(menuItem));

export const menuDoc = z.object({
	_id: t.id,
	siteId: t.id,
	name: z.string(),
	courses: courses
})
.partial().required({
	_id: true,
	siteId: true,   //FIXME only in server I think
	courses: true
});

export type MenuDoc = z.infer<typeof menuDoc>;

