import {SolidDateTimeWidget} from 'Browser/widgets/SolidDateTimeWidget';
import {Checkbox, Time, Date, Wrap, Text, SingleSelect} from 'Shared/forms/Inputs';
import {Repeater} from 'Shared/forms/Repeater';
import {SettingsData} from 'Shared/view/backend/Settings';
import {BackendSettings, FrontendSettings, GeneralSettings, PageEntry, StandardHome, StandardHomeFrontend, StandardHomeFrontendDesign, StandardHomeFrontendNav, VenueSettingsType,StandardMenu} from 'Shared/view/VenueSettingsType';
import {createEffect} from 'solid-js';
import {Show, createSignal} from 'solid-js';
import { css } from '@emotion/css'
import {PageProvider, usePage} from 'Shared/artists/PageProvider';
import {EditKeyComponent} from 'Common/components/EditKeyComponent';
import {EditComponent} from 'Common/components/EditComponent';
import {Document} from 'Common/PageConfig';

type Loc = {location:string[]};

const settingStyle = () => css({
	'x-tree': {
		display: 'block',
		margin: '1em 0 1em 2em',
		borderLeft: '#ddd 1px solid',
		borderTop: '#ddd 1px solid',
		paddingLeft: '0.5em',
	}
});


export function SettingsPage(props:SettingsData)
{
	return (
		<PageProvider page={props.page}>
			<div class={settingStyle()}>
				<General {...props.venue.settings.general} location={['general']}/>

				<Frontend {...props.venue.settings.frontend} location={['frontend']}/>

				<Backend {...props.venue.settings.backend} location={['backend']} />

				<Pages {...props.venue.settings.pages} location={['pages']} />

				<Implementations {...props.venue.settings} />
			</div>
		</PageProvider>
	);
}

function General(props:GeneralSettings & Loc)
{
	const edit = usePage().component('edit') as EditComponent<SettingsData,Document>

	const [show,setShow] = createSignal(false);

	let dayStartNode:HTMLDivElement;
	let dayEndNode:HTMLDivElement;
	let testNowNode:HTMLDivElement;

	createEffect(() => {
		if (show()) {
			(new SolidDateTimeWidget()).init(dayStartNode);
			(new SolidDateTimeWidget()).init(dayEndNode);
			(new SolidDateTimeWidget()).init(testNowNode);
		}
		else if (dayStartNode!=undefined) {
			(new SolidDateTimeWidget()).destroy(dayStartNode);
			(new SolidDateTimeWidget()).destroy(dayEndNode);
			(new SolidDateTimeWidget()).destroy(testNowNode);
		}
	});


//XXX cf passing 'required' thru to the input from the wrap

	return <x-tree>
		{sectionTitle('General settings',show,setShow)} 

		<Show when={show()}>
			<Wrap label='Enabled' required={true}>
				<Checkbox 
					component={edit}
					location={props.location}
					field='enabled'
					value={props.enabled}
				/>
			</Wrap>
			<Wrap label='Timezone' required={true}>
				<Text
					component={edit}
					location={props.location}
					field='timezone'
					value={props.timezone}
				/>
			</Wrap>
			<Wrap label='Day start' required={true}>
				<Time
					component={edit}
					location={props.location}
					field='dayStart'
					value={props.dayStart}
					ref={dayStartNode!}
				/>
			</Wrap>
			<Wrap label='Day end' required={true}>
				<Time
					component={edit}
					location={props.location}
					field='dayEnd'
					value={props.dayEnd}
					ref={dayEndNode!}
				/>
			</Wrap>
			<Wrap label='Test now' required={false}>
				<Date
					component={edit}
					location={props.location}
					field='testNow'
					value={props.testNow}
					ref={testNowNode!}
				/>
			</Wrap>
		</Show>
	</x-tree> ;
}

function Frontend(props:FrontendSettings & Loc)
{
	//TODO? possibly just allow one peer to be shown at a time
	const [show,setShow] = createSignal(false);
	const edit = usePage().component('edit') as EditComponent<SettingsData,Document>
;

	const typeOptions = {		//TODO type these keys?
		'differentDrummer':	'Different Drummer',
		'theTownie':		'The Townie',
	};

	const fontOptions = {		//TODO type these keys?
		'differentDrummer':	'Different Drummer',
		'theTownie':		'The Townie',
	};
//TODO add these mappings... fontIncludesTemplate: DifferentDrummerFonts


/*XXX possible future fields:
		palette: 'air',
		variablePalette: true			//XXX would need to include JS in the header to set these to 'air'
*/ 

	return (
		<x-tree>
			{sectionTitle('Frontend settings',show,setShow)} 

			<Show when={show()}>
				<Wrap label='venueClass' required={true}>
					<SingleSelect component={edit} location={props.location} field='venueClass' options={typeOptions} required={true} value={props.venueClass} />
				</Wrap>

				<Wrap label='fonts' required={true}>
					<SingleSelect component={edit} location={props.location} field='fonts' options={fontOptions} required={true} value={props.fonts} />
				</Wrap>
			</Show>
		</x-tree>
	);
}

function Backend(props:BackendSettings & Loc)
{
	const [show,setShow] = createSignal(false);
	const edit = usePage().component('edit') as EditComponent<SettingsData,Document>
;

	const typeOptions = {		//TODO type these keys?
		'App/backend/nav/differentDrummerNav.njk':	'App/backend/nav/differentDrummerNav.njk',
		'App/backend/nav/theDockNav.njk':			'App/backend/nav/theDockNav.njk',
		'App/backend/nav/theTownieNav.njk':			'App/backend/nav/theTownieNav.njk',
		'App/backend/nav/indieIslandNav.njk':		'App/backend/nav/indieIslandNav.njk',
	};

//XXX note the 2 uses of navTemplate here
	return (
		<x-tree>
			{sectionTitle('Backend settings',show,setShow)}

			<Show when={show()}>
				<Wrap label='Nav template' required={true}>
					<SingleSelect component={edit} location={props.location} field='navTemplate' options={typeOptions} required={true} value={props.navTemplate} />
				</Wrap>
			</Show>
		</x-tree>
	);
}

/*
	TODO Some page types could (optionally) allow certain things to be locked down
         Eg a type that allows a top banner to be specified in this file as JSON.
         Cf adding widgets to a page, some of which are static.
*/

function Pages(props:PageEntry & Loc) 
{
	const [show,setShow] = createSignal(false);

	return <x-tree>
		{sectionTitle('Pages',show,setShow)}

		<Show when={show()}>
			<Home {...props.home} location={[...props.location,'home']} />
			<Menu {...props.menu} location={[...props.location,'menu']} />
		</Show>
	</x-tree>;
}


function Home(props:StandardHome & Loc)
{
	const [show,setShow] = createSignal(false);
	const edit = usePage().component('edit') as EditComponent<SettingsData,Document>
;

	const typeOptions = {
		standardHome:			'Standard home',
		oldHome:				'Old home',
		differentDrummerHome:	'Different Drummer home'
		//XXX Maybe options...   plainHome|standard|plain|townieHome|dockHome|indieIslandHome | standardEvents | standardEvent
	};

	return <x-tree>
		{sectionTitle('Home',show,setShow)}

		<Show when={show()}>
			<Wrap label='Type' required={true}>
				<SingleSelect component={edit} location={props.location} field='type' options={typeOptions} required={true} value={props.type} />
			</Wrap>

			<FrontendPages {...props.frontend} location={[...props.location,'frontend']} />
		</Show>
	</x-tree>;
}

function Menu(props:StandardMenu & Loc)
{
	const [show,setShow] = createSignal(false);
	const edit = usePage().component('edit') as EditComponent<SettingsData,Document>
;

	const itemTypeOptions = {
		standard:	'Standard',
		imageless:	'Imageless',
	};

	return <x-tree>
		{sectionTitle('Menu',show,setShow)}

		<Show when={show()}>
			<Wrap label='Item type' required={true}>
				<SingleSelect component={edit} location={props.location} field='itemType' options={itemTypeOptions} required={true} value={props.itemType} />
			</Wrap>
		</Show>
	</x-tree>;
}

function FrontendPages(props: StandardHomeFrontend & Loc)
{
	const [show,setShow] = createSignal(false);

	return <x-tree>
		{sectionTitle('Frontend',show,setShow)}

		<Show when={show()}>
			<FrontendNav {...props.nav} location={[...props.location,'nav']} />

			<PageDesign {...props.design} location={[...props.location,'design']} />
		</Show>
	</x-tree>;
}

function FrontendNav(props: StandardHomeFrontendNav & Loc)
{
	const [show,setShow] = createSignal(false);
	const edit = usePage().component('edit') as EditComponent<SettingsData,Document>
;

	return <x-tree>
		{sectionTitle('Nav',show,setShow)}

		<Show when={show()}>
			<Wrap label='Shrink on scroll' required={true}>
				<Checkbox component={edit} location={props.location} field='shrinkOnScroll' value={props.shrinkOnScroll} />
			</Wrap>
		</Show>
	</x-tree>;
}

function PageDesign(props: StandardHomeFrontendDesign & Loc)
{
	const [show,setShow] = createSignal(false);
	const edit = usePage().component('edit') as EditComponent<SettingsData,Document>
;

	const typeOptions = {
		widgetList:		'Widget list',
		eventShowcase:	'Event showcase'
		//XXX cf sections, grid, ... 
	};  

	const entryOptions = {
		backend:	'Via backend',  //XXX maybe a 'tab' option for in a separate tab?
		settings:	'In settings'
		//XXX cf sections, grid, ...
	};

	return <x-tree>
		{sectionTitle('Design',show,setShow)}

		<Show when={show()}>
			<Wrap label='Type' required={true}>
				<SingleSelect component={edit} location={props.location} field='type' options={typeOptions} required={true} value={props.type} />
			</Wrap>

			<Wrap label='Entry' required={true}>
				<SingleSelect component={edit} location={props.location} field='entry' options={entryOptions} required={true} value={props.entry} />
			</Wrap>
		</Show>
	</x-tree>;

/*	XXX if having statically-defined vertical layout could include something like this here...

	widgets: [
		{type: 'banner', / *...* /},
		{type: 'events', daysToShow:90},
		{type: 'sections',name:'myInfo'},	//XXX maybe create a pane in the backend for this dynamic part
		{
			type: 'alternatingGrid',
			items: [
				{type:'imageLeft',caption:'x y z'},
				{type:'imageRight',caption:'x y z'}
			]
		},
	]
*/
}

/*
	XXX Possible backendPage example:
		pages: {
			home: {
				backend: {
					pageName: 'Home Page 2',
					/ * Else, if static entry: * /
					widgets: [ 	//XXX sections
						{
							ref: 'myInfo',
							label: 'A label for enterring sections'
						},
						//... 
					],
					/ * Possibly... * /
					tabs: [
						{name:'Posters', widgets: ['myPosters']}
					]
				}
			},
			events: {
				type: 'standardEvents',
				// image sizes probably not settable here unless special needs arise
			}
*/

function Implementations(props:VenueSettingsType)
{
	const [show,setShow] = createSignal(false);

//XXX cf removing need to remove the 'items' field below, or generalising it...
	const items = () => Object.entries(props.implementations).map(e => ({key:e[0], ...e[1]})); 

	return (
		<x-tree>
			{sectionTitle('Implementations',show,setShow)}

			<Show when={show()}>
				<Repeater 
					component='edit' 
					location={[]} 
					items={items()}
					renderTitle={(current) => current?.key ?? 'FIXME' }
					renderAddLabel={() => 'blah'}
				>
					{([item,index]) => <PageImplementation {...item}/>}
				</Repeater>
			</Show>
		</x-tree>
	);
}	

function PageImplementation(props)
{
	const edit = usePage().component('edit') as EditComponent<SettingsData,Document>;
	const editKey = usePage().component('editKey') as EditKeyComponent<SettingsData,Document>;

	return <>
		<Wrap label='Page ID' required={true}>
			<Text
				component={editKey}
				field='frontend/menu'
				value={props.key}
			/>
		</Wrap>

		<Wrap label='Template' required={true}>
			<Text
				component={edit}
				location={['implementations','frontend/menu']}
				field='template'
				value={props.template}
			/>
		</Wrap>
	</>;
}

function sectionTitle(label:string,show:()=>boolean ,setShow:(show:boolean)=>void)
{
	if (show())
		return <a href='' onClick={() => setShow(false)}><h3>&#x2795; {label}</h3></a>;
	else
		return <a href='' onClick={() => setShow(true)}><h3>&#x2796; {label}</h3></a>;
}

