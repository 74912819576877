import { css } from '@emotion/css';
import {locateSubDocument} from 'Common/ViewUtils';
import { Id, Location } from 'Common/config/PageConfigTypes';
import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {StatusIcons} from 'Shared/backend/recordStatus/StatusIcons';
import {SingleSelect,DateTime} from 'Shared/forms/Inputs';
import { Url } from 'Shared/model/basic';
import {Show} from 'solid-js';
import { SetStoreFunction } from 'solid-js/store';

const bottomBarStyle = () => css({
	position: 'sticky',
	bottom: 0,
	zIndex: 5,
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	background: 'white',
	borderTop: '1px solid lightgrey',
	padding: '1em 0 0.5em 0',
});

const statusBarStyle = () => css({
    display: 'flex',
	flexDirection: 'row',
	gap: 4,
    alignItems: 'center',
    fontSize: '1em',

    '.statusIcons': {
        display: 'flex'
    }
});


export interface IStatusBar<D> {
	docId: Id,
	location: Location,
	field: string;
	store: D;
	setStore: SetStoreFunction<D>;
	link: Url;
}


export function StatusBar<D>(props:IStatusBar<D>)
{
	const status = () => locateSubDocument(props.store,props.location).status;

	return (
		<div class={bottomBarStyle()}>
			<div class={statusBarStyle()}> 
				<StatusIcons status={status()} />

				<SingleSelect {...props} field='status' 
					options={ {'draft':'Draft','published':'Published','unpublished':'Archive'} } 
				/>

				<Show when={status()=='published'}>
					<a href={props.link} class='linkInline' target='_blank'><LinkOpen /></a>
				</Show>
			</div>
		</div>
	);
}

