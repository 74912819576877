
export function ClockCircle(props)
{
	return (
		<svg id="clock_circle" class="icon" style={`fill:${props.fill ?? 'orange'}; stroke:${props.stroke ?? 'orange'};`} viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		  <defs></defs>
		  <path d="M 32 2 C 15.433 2 2 15.433 2 32 C 2 48.567 15.433 62 32 62 C 48.567 62 62 48.567 62 32 C 62 15.433 48.567 2 32 2 Z M 54.324 32.463 L 54.193 38.303 C 54.818 38.897 27.416 38.289 27.416 38.116 C 27.416 38.116 27.439 18.55 27.603 9.045 C 27.603 9.045 34.484 8.567 34.428 9.084 L 34.213 32.303 L 54.324 32.463 Z" bx:origin="-0.033333 -0.033333"></path>
		</svg>
	);
}

