import {LinkOpen} from 'Shared/backend/fonts/icons/LinkOpen.svg';
import {ImageUpload2, Label} from 'Shared/forms/ImageUpload';
import {SingleSelect, Wrap} from 'Shared/forms/Inputs';
import {Show} from 'solid-js'
import {Location} from 'Common/config/PageConfigTypes';


export interface IImageSelector
{
	menu,
	//XXX has 'menu item' props in here now
	location: Location,
	imageType: 'photo';  //TODO add others
	imageDimensions: 'portrait'|'landscape'|'square'  // keyof typeof imageSize
}

export function ImageSelector(props:IImageSelector)
{
	return (<>
		<header-regular>Image</header-regular>

		<div id='builder_sharePoster'>
			<Wrap label='Image type' required={false} >
				<SingleSelect {...props} field='imageType' 
					options={ {
						'none':'No image: VOS creates poster with item info', 
						'photo':'Photo or Logo'
					} } 
				/>
			</Wrap>

			<Show when={props.brandName != null}>
				<div class='search'>
					<a href={`http://images.google.com/images?um=1&hl=en&safe=active&nfpr=1&q=${props.brandName}+${props.name}+logo`} target='_blank'>
						<label class={props.labelStatus}>    
							Search Google for image &nbsp; <LinkOpen/>
						</label>
					</a>
				</div>
			</Show>

			<Show when={props.imageType == 'photo'}>
				<Wrap label='Dimensions' required={false}
					instructionsAfterLabel='Will be cropped to square on your menu, but full dimensions will show on details page'
				>
					<SingleSelect {...props} field='imageDimensions' required={true} 
						options={ {
							'landscape':	'▬ Landscape',
							'portrait':		'▋ Portrait',
							'square':		'■ Square'
						} } 
					/>
				</Wrap>
			</Show>

			<x-field class='imgUploadButton'>
				<Label {...props} labelText='' />
				<ImageUpload2 
					image={props.image} setImage={props.setImage}
					loadingDataUrl={props.loadingDataUrl} setLoadingDataUrl={props.setLoadingDataUrl}
					page={props.page} 
					id={props.menu._id} 
					location={[...props.location,'image']} 
					permission='photo' 
					required={true} 
					formats={props.imageDimensions} 
					previewSize='medium'
				/>
			</x-field>
		</div>
	</>);

//					{...locateSubDocument(props.menu,[...props.location,'image'])} 


	// TODO combine with event/imgeSelector.njk 
	// FIXME having the same minWidth for all ratios isnt good 
	// FIXME: think we should get the dims from component - or maybe the config? 
}	

