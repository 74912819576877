
import {PageFunctions} from 'Browser/PageFunctions';
import {VenueUrls} from 'Common/VenueUrls';
import {usePage} from 'Shared/artists/PageProvider';
import {stripZeros} from 'Shared/backend/menu/Price';
import {For, Show} from 'solid-js';
import {unwrap} from 'solid-js/store';
import { productTypeSelector } from './MenuPage';

export function StandardMenuItem(props) 
{
/*
	{# todo ben: The id "item{{item.id}} below is used as a html #anchor to jump to the item on the menu, however we need to compensate
	for the sticky menu nav which covers about 50px of the top of the item.
	Test it by going to the menu item GUI and on your 'Summary' preview click the 'view on website menu' button. 
	That will open the menu in a new tab and drop you the items anchor #}
*/	

//TODO remove url generation higher up frontend caller stack...

	const imageUrl = () => {
		if (!props.item.image?.hash)
			return '';

		const url = (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),
			props.item.image.hash,
			props.item.image,
			'MenuItem-image',
			'medium'
		);
		return url;
	};

	return (
		<div id={props.id} class={`menuItem ${productTypeSelector(props.productType)}`}>
			<div class='imageWrap'>
				<div class={`${props.item.imageDimensions}Dimension`}>
					<div class='image'>
						<div class='ratio'>
							<div>    
								<div style={`background-image: url('${imageUrl()}')`} />
							</div>
						</div>      
					</div>
				</div>
			</div>
			<div class='detailsWrap'>
				<TitleWrap item={props.item} />

				<div class='descriptionWrap'>
					{props.item.tagline}
				</div>

				<MenuItemPrices item={props.item}/>
			</div>
		</div>
	);
}

export function TitleWrap(props)
{
	const tags = () => unwrap(props.item.tags) ?? [];

	//FIXME townie specific
	const tagsStr = () => tags().filter(i => i!='0%' && i!='☺').join(' ');

	return (
		<div class='titleWrap'>
			<span class='title'>
				{props.item.brandName} {props.item.name}
				<Show when={tags().includes('0%')}>
					{' '}0%
				</Show>
				<Show when={tags().includes('☺')}>
					{' '}☺
				</Show>
			</span>
			<Show when={props.item.variety}>
				{' '}
				<span class='small'>{props.item.variety} {props.item.varietyOther}</span>
			</Show>
			<Show when={tagsStr().length > 0 }>
				{' '}
				<span class='small'> 
					({tagsStr()})
				</span>
			</Show>

			<Show when={props.item.year || props.item.region || props.item.state }>
				{' '}
				<span class='small'>
					{props.item.year} {props.item.region} {props.item.state?.toUpperCase()}
					<Show when={props.item.country != 'Australia'}>
						{props.item.country}
					</Show>
				</span>
			</Show>
		</div>
	);
}

export function MenuItemPrices(props)
{
	return (
		<div class='priceWrap'>
			<For each={props.item.prices}>{ unit =>
				<Show when={unit.price !== null }>
					{/* XXX probably shouldnt be required but currently these items contain nulls */}
					<div class='priceInner'>
						{/* Only 1 of these unit options will appear */}
						<span class='unit'>{capitalise(unit.size)}{capitalise(unit.description)}{' '}</span>
						<span class='price'>${stripZeros(unit.price)}</span>
					</div>
				</Show>
			}</For>
		</div>
	);
}

function capitalise(s:string) 
{
	const str = s ?? '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

