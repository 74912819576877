import { z } from 'zod';
import {Components,DocumentQueries, Location} from 'Common/config/PageConfigTypes';
import Assert from 'Common/Assert';
import {menuDoc as menuModel,MenuDoc as MenuDoc} from 'Shared/model/Menu';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import {MenuItemPage} from 'Shared/backend/menu/MenuItemPage';

export const menuItemParams = backendParams.extend({
	course: z.string(),
	index0: z.number()
}).strict();	
export type MenuItemParams = z.infer<typeof menuItemParams>;


export const menuItemData = backendData.extend({
	params: menuItemParams,
	menu: menuModel,
}).strict();
export type MenuItemData = z.infer<typeof menuItemData>;


export class MenuItem extends BackendPageConfig<MenuItemData,MenuItemParams>
{
	static readonly pageName = 'backend/menuItem';
	name() { return MenuItem.pageName; }

	access() { return 'venueMenuEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: '',
			template: MenuItemPage
		};
	}

	documents(params:MenuItemParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			menu: {
				type:'object',
				collection: 'menu',
				aggregate: () => [
					{$match: {slug:params.slug}}
				]
			}
		});
	}

	// Operation permissions used by the server...
	permissions()
	{
		return ({
//XXX ==> image			
			editMenu: {collection:'menu'},
			photo: { collection:'menu', assetFolder:'MenuItem-image'},
		});
	}

	components(): Components<MenuItemData>
	{
		return ({
/*		
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Course.pageName,component:'breadcrumbs'},
				link: (data:MenuItemData) => '/admin/menu/'+data.params.course+'/'+data.params.index0  //TODO? move this code into Links file
			},
*/			
			editItem: {
				type:'edit2',
				collection: 'menu',
				subdocument: []
			},

			deleteItem: {
				type: 'editArray',		//XXX COULD be split into addItem, deleteItem?
				collection: 'menu',
				selector: '#unused',
				locateListParent: (doc:MenuDoc) => doc.courses,
				fieldName: (location:Location) => Assert.toString(location[0]),
				redirect: () => `/admin/menu`
			},

			prices: {
				type: 'repeater',
				selector: '#prices repeater-items',
				collection: 'menu',
				locate: (doc:MenuDoc,location:Location)				=> doc.courses[location[0]][Assert.toNumber(location[1])].prices![Assert.toNumber(location[2])],
				locateListParent: (doc:MenuDoc,location:Location)	=> doc.courses[location[0]][Assert.toNumber(location[1])],
				fieldName: () => 'prices',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},
		});	
	}
}
