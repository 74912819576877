import {venueDoc,VenueDoc as VenueDoc} from 'Shared/model/venue';
import { z } from 'zod';
import {PageFunctions} from 'Browser/PageFunctions';
import {Components,DocumentQueries,ImageUploaderComponent, Location} from 'Common/config/PageConfigTypes';
import {DateTimeWidget} from 'Browser/widgets/DateTimeWidget';
import {ImageUploadWidget} from 'Browser/widgets/ImageUploadWidget';
import {MultiSelectWidget} from 'Browser/widgets/MultiSelectWidget';
import * as imageSize from 'Shared/model/imageSize';
import {HtmlEditorWidget} from 'Browser/widgets/HtmlEditorWidget';
import {Venue} from 'Shared/view/backend/Venue';
import {EmailField} from 'Browser/NjkInputTypes';
import {processInput} from 'Browser/Input';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import Assert from 'Common/Assert';


export const contactParams = z.object({ 
	tab: z.string().optional()
}).strict();	
export type ContactParams = z.infer<typeof contactParams>;

export const contactData = backendData.extend({
	params: contactParams,
	tabs: z.any(),				//XXX any
	venue:venueDoc
}).strict();
export type ContactData = z.infer<typeof contactData>;


export class Contact extends BackendPageConfig<ContactData,ContactParams>
{
	static readonly pageName = 'backend/contact';
	name() { return Contact.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	widgets(pageWrapper:IPageWrapper)
	{
		const urls = new VenueUrls(this.build,this.venue.key);

		return {
			[ImageUploadWidget.key()]: new ImageUploadWidget(pageWrapper),
			[DateTimeWidget.key()]: new DateTimeWidget(),
			[MultiSelectWidget.key()]: new MultiSelectWidget(),
			[HtmlEditorWidget.key()]: new HtmlEditorWidget(urls),
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} contact`,
			template: 'App/backend/contact/contactPage.njk'
		};
	}

	documents(params:ContactParams):DocumentQueries
	{
		return ({
			...super.documents(params),
			/* venue is included in all venue page requests */
		});
	}

	components():Components<ContactData>
	{
		return ({
			breadcrumbs: {
				type: 'breadcrumbs',
				parent: {page:Venue.pageName,component:'breadcrumbs'},
				link:  () => 'backend/contact'
			},
			tabs : {
				type: 'tabs',
				names: ['general','emails','hours','social','thirdParty','about','showcaseCourses'],
				initial: (data:ContactData) => data.params.tab ?? 'general'
			},
			editVenue: {
				type:'edit',
				collection: 'venue',
				locate: (doc:ContactData) => doc
			},

			emails: {
				type: 'repeater',
				selector: '#emails repeater-items',
				collection: 'venue',
				locate:				(doc:VenueDoc,location:Location) => doc.email[Assert.toNumber(location[0])],
				locateListParent:	(doc:VenueDoc) => doc,
				fieldName:			() => 'email',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => field!='email' ? value : processInput(new EmailField(),value)
			},

			operatingHours: {
				type: 'repeater',
				selector: '#operatingHours repeater-items',
				collection: 'venue',
				locate:				(doc:VenueDoc,location:Location) => doc.operatingHours[Assert.toNumber(location[0])],
				locateListParent:	(doc:VenueDoc) => doc,
				fieldName:			() => 'operatingHours',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},

			otherSocialMedia: {
				type: 'repeater',
				selector: '#otherSocialMedia repeater-items',
				collection: 'venue',
				locate:				(doc:VenueDoc,location:Location) => doc.otherSocialMedia[Assert.toNumber(location[0])],
				locateListParent:	(doc:VenueDoc) => doc,
				fieldName:			() => 'otherSocialMedia',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},

			thirdPartyServices: {
				type: 'repeater',
				selector: '#thirdPartyServices repeater-items',
				collection: 'venue',
				locate:				(doc:VenueDoc,location:Location) => doc.thirdPartyServices[Assert.toNumber(location[0])],
				locateListParent:	(doc:VenueDoc) => doc,
				fieldName:			() => 'thirdPartyServices',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},

			aboutList: {
				type: 'repeater',
				selector: '#aboutList repeater-items',
				collection: 'venue',
				locate:				(doc:VenueDoc,location:Location) => doc.aboutList[Assert.toNumber(location[0])],
				locateListParent:	(doc:VenueDoc) => doc,
				fieldName:			() => 'aboutList',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},

			//arranges display and order of Food Courses on Frontend
			//todo ben move from venue.ts to appropriate location
			showcaseCoursesFood: {
				type: 'repeater',
				selector: '#showcaseCoursesFood repeater-items',
				collection: 'venue',
				locate:				(doc:VenueDoc,location:Location) => doc.showcaseCoursesFood[Assert.toNumber(location[0])],
				locateListParent:	(doc:VenueDoc) => doc,
				fieldName:			() => 'showcaseCoursesFood',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},

			//arranges display and order of Drinks Courses on Frontend
			//todo ben move from venue.ts to appropriate location
			showcaseCoursesDrinks: {
				type: 'repeater',
				selector: '#showcaseCoursesDrinks repeater-items',
				collection: 'venue',
				locate:				(doc:VenueDoc,location:Location) => doc.showcaseCoursesDrinks[Assert.toNumber(location[0])],
				locateListParent:	(doc:VenueDoc) => doc,
				fieldName:			() => 'showcaseCoursesDrinks',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value,	
			},

			logo: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'venue',
				field: 'logo',
				locate: (doc:ContactData) => doc,
				assetFolder: 'Venue-logo',
				sizes: imageSize.venueLogo,
				previewSize: 'full'
			},
			photo: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'venue',
				field: 'photo',
				locate: (doc:ContactData) => doc,
				assetFolder: 'Venue-photo',
				sizes: imageSize.venuePhoto,
				previewSize: 'full'
			},
			logoLandscape: <ImageUploaderComponent>{
				type:'imageUploader', 
				collection: 'venue',
				field: 'logoLandscape',
				locate: (doc:ContactData) => doc,
				assetFolder: 'Venue-logoLandscape',
				sizes: imageSize.square,
				previewSize: 'full'
			},
		});
	}

	beforeDisplay(data:ContactData) 
	{
		super.beforeDisplay(data);

		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		funcs.updateLocationWithTabs(this.inBrowser,`/admin/contact/${data.tabs.selected}`);

		if (data.venue.logo==undefined)
			data.venue.logo = <any>{};
		funcs.imageUploadUrl(urls,data.venue._id,Contact.pageName,'logo',data.venue.logo!,'Venue-logo','Venue',[],'full','defaults/profile3x4.png');

		if (data.venue.photo==undefined)
			data.venue.photo = <any>{};
		funcs.imageUploadUrl(urls,data.venue._id,Contact.pageName,'photo',data.venue.photo!,'Venue-photo','Venue',[],'full','defaults/profile3x2.png');

		if (data.venue.logoLandscape==undefined)
			data.venue.logoLandscape = <any>{};
		funcs.imageUploadUrl(urls,data.venue._id,Contact.pageName,'logoLandscape',data.venue.logoLandscape!,'Venue-logoLandscape','Venue',[],'full','defaults/profile3x2.png');
	}
}

