import {PageFunctions} from 'Browser/PageFunctions';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import { z } from 'zod';
import {StickySubNavWidget} from 'Browser/widgets/StickySubNavWidget';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData, frontendParams} from 'Common/FrontendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import {createImageUrl, createImageUrls} from 'Common/ViewUtils';
import {SliderWidget} from 'Browser/widgets/frontend/SliderWidget';
import {ParallaxWidget} from 'Browser/widgets/frontend/ParallaxWidget';
import {FadePageInWidget} from 'Browser/widgets/FadePageInWidget';
import {ExpandedCollapsedWidget} from 'Browser/widgets/frontend/ExpandedCollapsedWidget';
import {ScrollRangeVariableWidget} from 'Browser/widgets/frontend/ScrollRangeVariableWidget';

export const hireParams = frontendParams.extend({ }).strict();	
export type HireParams = z.infer<typeof hireParams>;


export const hireData = frontendData.extend({
	pageDisplayName: z.string(),
	space: z.any()
}).strict();
export type HireData = z.infer<typeof hireData>;



export class Hire extends FrontendPageConfig<HireData,HireParams>
{
	static readonly pageName = 'frontend/hire';
	name() { return Hire.pageName; }

	access() { return 'venueFrontend' as AccessAreaName; }

	widgets(pageWrapper:IPageWrapper)
	{
		return {
			[StickySubNavWidget.key()]: new StickySubNavWidget(),
			[ParallaxWidget.key()]: new ParallaxWidget(['.parallaxWidget']),
			[FadePageInWidget.key()]: new FadePageInWidget(),
			[ScrollRangeVariableWidget.key()]: new ScrollRangeVariableWidget(),
			[ExpandedCollapsedWidget.key()]: new ExpandedCollapsedWidget(),
			[SliderWidget.key()]: new SliderWidget() 
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			htmlClasses: `${this.htmlClasses} hirePage`,
			template: 'App/frontend/hirePage/standard.njk',
			pageDisplayName: 'Hire'
		};
	}

	documents(params:HireParams)
	{
		return <DocumentQueries> ({
			...super.documents(params),

			hire: {
				type: 'object',
				collection: 'hire',
				aggregate: () => [ ]
			},	  
			email: {
				type: 'object',
				collection: 'email',
				aggregate: () => [
					{$match:{type:'hire'}}
				]
			}
		});
	}

	components()
	{
		return ({
			...super.components()
		});
	}

	beforeDisplay(data:HireData)
	{
		super.beforeDisplay(data);

		const urls = new VenueUrls(this.build,this.venue.key);
		const funcs = new PageFunctions();

		for (const space of (<any>data).hire.space ?? []) {
			if (space.photo!=undefined)
				space.photo.url = funcs.createImageUrl(urls,space.photo,'Space-photo','medium');
			if (space.photo2!=undefined)
				space.photo2.url = funcs.createImageUrl(urls,space.photo2,'Space-photo2','medium');
			if (space.photo3!=undefined)
				space.photo3.url = funcs.createImageUrl(urls,space.photo3,'Space-photo3','medium');
			if (space.photo4!=undefined)
				space.photo4.url = funcs.createImageUrl(urls,space.photo4,'Space-photo4','medium');
		}

		for (const section of (<any>data).hire.showcase ?? []) {
			createImageUrl(urls,section.image,'Showcase-image','medium'); 
		createImageUrls(urls,section.items,'image','hire-showcaseCarouselImage','large');
	}
	}
}


