import {Location} from 'Common/config/PageConfigTypes';
import {createEffect, createMemo, JSX, onMount} from 'solid-js';
import {Repeater} from 'Shared/forms/Repeater';
import {CreateArrayItemMessage2, DeleteArrayItemMessage2, ReorderArrayMessage2, UpdateMessage2} from 'Common/Messages';
import {Id} from 'Common/Id';
import { SetStoreFunction } from 'solid-js/store';


export interface ISolidRepeaterProps<ItemData> {
	store: any,
	setStore: SetStoreFunction<any>,
	page,
	permission:string,		//TODO maybe use keyof permissions()
	docId:Id,
	location: Location,
	field: string,
	renderTitle: (current:ItemData) => string,
	renderAddLabel: () => JSX.Element|string,
	children: (item:ItemData[],index:number) => JSX.Element,
}

export function StoreRepeater<ItemData>(props:ISolidRepeaterProps<ItemData>)
{
//TODO remove EditableArray now?? Or parts of it?

	/*
		Convert the store and location into something more like a signal. Passing in a signal would be
		more convenient here, but the DB operations are expecting a store.
	*/
	/* Allowing the array to be undefined. */
	const items:ItemData[] = createMemo(() => valueFromStore(props) ?? []); 
	const setItems = (value:ItemData) => props.setStore(...props.location,props.field,value);

	return (
		<Repeater {...props} 
			ref={props.ref}
			items={items()} 
			setItems={setItems} 
			deleteItem={confirmAndDeleteItem} 
			reorder={(oldIndex:number,newIndex:number) => reorder(props,oldIndex,newIndex) } 
		/>
	);
}


function reorder(props,oldIndex:number,newIndex:number)
{
	const msg = new ReorderArrayMessage2(props.page.name(),props.permission,props.docId,[...props.location,props.field],oldIndex,newIndex);
	props.page.server.sendOperationOptimistically(msg);
}

export async function addItemAndOpen<ItemData>(props:ISolidRepeaterProps<ItemData>,itemsNode:HTMLElement,initData:XXX)
{
	/* Update the browser: */
	let value = valueFromStore(props);

	/* Create the repeater if not present */
	if (value==undefined) {
		props.setStore(...props.location,props.field,[]);

		const msg = new UpdateMessage2(props.page.name(),props.permission,props.store._id,props.location,{[props.field]:[]});
       	await props.page.server.sendOperation(msg);

		value = valueFromStore(props);
	}

	props.setStore(...props.location,props.field,value.length,initData);

	/* Open the new item: */
	const kids = [...itemsNode.children];
	kids[kids.length - 1].querySelector('repeater-bar').click();

	/* Update the DB: */
	const msg = new CreateArrayItemMessage2(props.page.name(),props.permission,props.store._id,[...props.location,props.field],initData);
	await props.page.server.sendOperation(msg);
}

async function confirmAndDeleteItem<ItemData>(event:Event,props:ItemProps<ItemData>)
{
	event.stopPropagation();

	if (!confirm('Are you sure you wish to delete this item?'))
		return;

	const array = valueFromStore(props);
	const newArray = [...array.slice(0,props.index), ...array.slice(props.index+1)] 
	props.setStore(...props.location,props.field,newArray);

	const msg = new DeleteArrayItemMessage2(props.page.name(),props.permission,props.docId,[...props.location,props.field],props.index);
	await props.page.server.sendOperation(msg);
}

/* Returns undefined if not found */
function valueFromStore(props:{store:any,location:Location,field:string})
{
	const parentPath = props.location ?? [];

	let pos = props.store;
	for (const part of parentPath) 
		pos = pos?.[part];

	if (pos==undefined)
		return undefined;

	return pos[props.field];
}

